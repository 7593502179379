import classNames from "classnames";
import Image from "next/image";
import Timestamp from "rivals/components/shared/Article/Timestamp";
import PremiumContent from "rivals/components/shared/Icons/PremiumContent";
import Link from "rivals/components/shared/Link";
import { isNationalUmbrellaSite } from "rivals/shared/utils/nationals";
import { imageLoader } from "rivals/shared/utils/nextImage";
import { setData } from "rivals/shared/utils/qa";
import styles from "./ContentCard.module.scss";
import ContentType from "./ContentType";
import { CONTENT_CARD_VARIANTS, Props } from "./types";

const sameLineDivider = <span>&nbsp;•&nbsp;</span>;

/**
 * Expect changes in this file when it's hooked to consume real data
 * The layout, structure, and styles should remain the same
 */

const ContentCard = ({
  authorName,
  className = "",
  contentType,
  contentUrl,
  darkMode = false,
  headlinePrimaryUrl,
  heroDesktopUrl,
  kind,
  liveAt,
  philter,
  site,
  summary,
  title,
  variant
}: Props): React.JSX.Element => {
  const premiumIconColor =
    site?.primaryColor && !isNationalUmbrellaSite(site?.shortName)
      ? `#${site.primaryColor}`
      : styles.hoops;

  const classes = classNames(
    styles.contentCard,
    styles[`${variant}ContentCard`],
    { [styles.darkMode]: darkMode },
    className
  );

  const imageUrl =
    variant === CONTENT_CARD_VARIANTS.HIGHLIGHTED
      ? headlinePrimaryUrl
      : heroDesktopUrl;

  return (
    <Link
      className={classes}
      data={`${philter}-content-card`}
      href={contentUrl || ""}
    >
      {!!imageUrl && (
        <Image
          alt={title || ""}
          className={styles.image}
          height={0}
          loader={imageLoader}
          priority
          role="img"
          src={imageUrl}
          title={title}
          width={0}
          {...setData(philter, "content-card-image")}
        />
      )}

      <div className={styles.detailsWrapper}>
        {/* max length is 75 characters */}
        <h3 className={styles.title} title={title}>
          {title}
        </h3>

        {/* max length is 120 characters */}
        {!!summary && (
          <p className={styles.summary} title={summary}>
            {summary}
          </p>
        )}

        <div className={styles.extraDetails}>
          {kind === "premium" && (
            <PremiumContent
              fillBg={premiumIconColor}
              height="15px"
              width="26px"
            />
          )}
          {<ContentType contentType={contentType} darkMode={darkMode} />}
          <div className={styles.extraDetailsText}>
            {liveAt && (
              <Timestamp className={styles.liveAt} liveAt={liveAt} short />
            )}
            {!!(liveAt && authorName) && sameLineDivider}
            {authorName && <span title={authorName}>{authorName}</span>}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ContentCard;
