"use client";

import Select from "antd/lib/select";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import ButtonWrapper from "rivals/components/shared/Button";
import CollegeLogo from "rivals/components/shared/CollegeLogo";
import Basketball from "rivals/components/shared/Icons/Basketball";
import Chevron from "rivals/components/shared/Icons/Chevron";
import ExternalLinkIcon from "rivals/components/shared/Icons/ExternalLinkIcon";
import Football from "rivals/components/shared/Icons/Football";
import { variantFillColor } from "rivals/components/shared/Icons/iconUtils";
import EmptyStateMessage from "rivals/components/shared/Widgets/EmptyStateMessage";
import WidgetHeader from "rivals/components/shared/Widgets/WidgetHeader";
import WidgetLayout from "rivals/components/shared/Widgets/WidgetLayout";
import widgetStyles from "rivals/components/shared/Widgets/Widgets.module.scss";
// eslint-disable-next-line no-restricted-imports
import { configureClientComponentApi } from "rivals/services/configureClientComponentApi";
import { GetScoresAndSchedulesResponse } from "rivals/services/container";
import { buildRefLink } from "rivals/services/referral";
import {
  NATIONAL_SITE_IDENTIFIERS,
  NUMERIC_FRACTIONS,
  PRIMARY_SPORTS,
  ROTATE_90,
  SEE_MORE
} from "rivals/shared/constants";
import { GameScore, ScoresSchedule } from "rivals/shared/interfaces/GameScore";
import { WIDGET_SIZE } from "rivals/shared/interfaces/Widgets";
import { setData } from "rivals/shared/utils/qa";
import GameCard from "./GameCard";
import styles from "./ScoresWidget.module.scss";
import strings from "./strings";
import { Props } from "./types";

function ScoresWidget({
  buttonFullWidth = false,
  darkMode = false,
  logoUrl,
  primaryColor,
  removeBorder = false,
  reflink,
  scheduleData,
  selectedSiteShortname,
  showTopBar = true,
  siteId,
  size = WIDGET_SIZE.MEDIUM, // default to display 5 games
  sponsor,
  sport = PRIMARY_SPORTS.FOOTBALL,
  width
}: Props): React.JSX.Element {
  const philter = "scores-schedule-widget";
  const [scoresScheduleData, setScoresScheduleData] = useState<ScoresSchedule>(
    scheduleData
  );
  const year =
    scoresScheduleData?.currentSeason ||
    scoresScheduleData?.league?.currentSeason;
  const [selectedSport, setSelectedSport] = useState<PRIMARY_SPORTS>(sport);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const { widgetTitle, sectionTitles } = strings;
  const iconFill = variantFillColor(darkMode);
  const buttonUrl = scoresScheduleData?.teamId
    ? `${scoresScheduleData?.alias.url}schedule`
    : strings.leagueScheduleUrl(selectedSport);
  const isNationalSite =
    selectedSiteShortname &&
    NATIONAL_SITE_IDENTIFIERS.includes(
      selectedSiteShortname?.toLocaleLowerCase()
    );

  let titleIcon;
  if (logoUrl) {
    titleIcon = (
      <CollegeLogo
        collegeLogo={logoUrl}
        collegeName={selectedSiteShortname}
        logoSize={36}
      />
    );
  } else {
    titleIcon =
      selectedSport == PRIMARY_SPORTS.FOOTBALL ? (
        <Football fill={iconFill} />
      ) : (
        <Basketball fill={iconFill} />
      );
  }

  const options = [PRIMARY_SPORTS.FOOTBALL, PRIMARY_SPORTS.BASKETBALL].map(
    sport => {
      return {
        label: (
          <div className={styles.optionItem}>
            {sport == PRIMARY_SPORTS.FOOTBALL ? (
              <Football fill={styles.abyss} height={"24"} width={"24"} />
            ) : (
              <Basketball fill={styles.abyss} height={"24"} width={"24"} />
            )}
            <span className={styles.optionName}>{sport}</span>
          </div>
        ),
        value: sport
      };
    }
  );

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const apiService = configureClientComponentApi();
      const response: GetScoresAndSchedulesResponse = await apiService.getScoresAndSchedules(
        {
          siteId: siteId,
          sport: selectedSport
        }
      );
      setScoresScheduleData(response);
    };
    // Fetch data for sport on change
    fetchData();
  }, [selectedSport, siteId]);

  useEffect(() => {
    setSelectedSport(sport);
  }, [sport]);

  const widgetHeader = (
    <WidgetHeader
      actionIcon={
        <Select
          className={styles.titleSelect}
          dropdownStyle={{ borderRadius: 0, padding: "4px 0" }}
          onChange={async (value: PRIMARY_SPORTS) => {
            setSelectedSport(value);
          }}
          onDropdownVisibleChange={(open: boolean): void => {
            setSelectOpen(open);
          }}
          options={options}
          popupClassName={styles.rankingTypeSelectDropdown}
          size={"large"}
          suffixIcon={
            <Chevron
              animateRotation={true}
              fill={variantFillColor(darkMode)}
              rotate={selectOpen ? ROTATE_90 : -ROTATE_90}
            />
          }
          value={selectedSport}
          variant={"borderless"}
          {...setData(philter, "sport-selector")}
        />
      }
      eyebrowText={selectedSport.toUpperCase()}
      leftPadding={12}
      title={widgetTitle}
      titleIcon={titleIcon}
    />
  );

  const iconStyles = classNames(
    {
      [widgetStyles.darkMode]: darkMode,
      [styles.buttonClass]: !buttonFullWidth,
      [widgetStyles.smallButton]: buttonFullWidth
    },
    widgetStyles.externalIcon
  );

  const widgetButton = (
    <ButtonWrapper
      buttonText={SEE_MORE}
      className={iconStyles}
      dark={darkMode}
      fullWidth
      icon={<ExternalLinkIcon fill="none" />}
      inverted
      shouldAddNewTab
      url={buildRefLink(buttonUrl, reflink)}
    />
  );

  const emptyState = (
    <EmptyStateMessage
      darkMode={darkMode}
      message={strings.notAvailable(year)}
      philter={philter}
    />
  );

  if (!scoresScheduleData) {
    return (
      <WidgetLayout
        button={widgetButton}
        darkMode={darkMode}
        removeBorder={removeBorder}
        sidePadding={0}
        title={widgetHeader}
        width={width}
      >
        <div
          className={classNames({
            [widgetStyles.darkMode]: darkMode
          })}
          {...setData(philter)}
        >
          {emptyState}
        </div>
      </WidgetLayout>
    );
  }

  const { currentGames, nextGames, previousGames } = scoresScheduleData;
  const yahooUpcomingGames = nextGames || [];
  const yahooPreviousGames = previousGames || [];
  const current = currentGames ? currentGames.slice(0, size) : [];
  const remainingSlots = size - current.length;
  const half = remainingSlots * NUMERIC_FRACTIONS.HALF;
  const upcomingOffset = Math.max(
    Math.ceil(half) - (yahooUpcomingGames.length || 0),
    0
  );
  const previousOffset = Math.max(
    Math.floor(half) - (yahooPreviousGames.length || 0),
    0
  );
  const upcoming = yahooUpcomingGames.slice(
    0,
    Math.ceil(half) + previousOffset
  );
  const previous = yahooPreviousGames.slice(
    0,
    Math.floor(half) + upcomingOffset
  );

  function teamRecord(
    title: string,
    wins: number,
    losses: number
  ): React.JSX.Element {
    return (
      <div className={styles.record} {...setData(philter, `${title}-record`)}>
        <div className={styles.winLoss}>
          {wins} - {losses}
        </div>
        <div>{title}</div>
      </div>
    );
  }

  const records = scoresScheduleData.overallRecord &&
    scoresScheduleData.conferenceRecord && (
      <div className={classNames(styles.scheduleSection, styles.records)}>
        {teamRecord(
          strings.overall,
          scoresScheduleData.overallRecord.wins,
          scoresScheduleData.overallRecord.losses
        )}
        {teamRecord(
          strings.conference,
          scoresScheduleData.conferenceRecord.wins,
          scoresScheduleData.conferenceRecord.losses
        )}
      </div>
    );

  function gameList(games: GameScore[], title: string): React.JSX.Element {
    return (
      <div
        className={classNames(styles.scheduleSection, {
          [styles.finshedSection]: title == sectionTitles.finished,
          [styles.finshedSectionDarkMode]:
            title == sectionTitles.finished && darkMode
        })}
      >
        <div className={styles.scheduleHeader}>{title}</div>
        <div className={styles.games}>
          {games.map((game, index) => (
            <GameCard
              darkMode={darkMode}
              gameData={game}
              key={`${title}-${index}`}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <WidgetLayout
      button={widgetButton}
      buttonFullWidth={buttonFullWidth}
      darkMode={darkMode}
      removeBorder={removeBorder}
      sidePadding={0}
      sponsor={sponsor}
      title={widgetHeader}
      topBorderColor={
        showTopBar && !isNationalSite ? `#${primaryColor}` : undefined
      }
      width={width}
    >
      <div {...setData(philter)}>
        {records}
        {current.length + upcoming.length + previous.length == 0 && emptyState}
        {current.length > 0 && gameList(current, sectionTitles.live)}
        {upcoming.length > 0 && gameList(upcoming, sectionTitles.upcoming)}
        {previous.length > 0 && gameList(previous, sectionTitles.finished)}
      </div>
    </WidgetLayout>
  );
}

export default ScoresWidget;
