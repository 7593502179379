"use client";

import { JSX } from "react";
import useIsBenjiReady from "rivals/components/shared/Ads/Benji/BenjiRefresh/useIsBenjiReady";
import { useGetRenderAdsForUser } from "rivals/shared/app/client";
import type { ContainerProps } from "./types";

/**
 * Client component wrapper to fetch user data for ads
 *
 * @param ad - ad component to be wrapped
 * @param forceAdToFront - boolean to force the ad to the front via z-index
 * @returns a wrapped ad component with user permissions as props
 */
export default function AdWrapper({
  ad: Ad,
  forceAdToFront = false,
  adStyles,
  location,
  locationCount,
  size,
  slotNumber
}: ContainerProps): JSX.Element {
  const isBenjiReady = useIsBenjiReady();

  const { adSettings, user } = useGetRenderAdsForUser();

  return (
    <Ad
      adSettings={adSettings}
      adStyles={adStyles}
      forceAdToFront={forceAdToFront}
      isBenjiReady={isBenjiReady}
      location={location}
      locationCount={locationCount}
      size={size}
      slotNumber={slotNumber}
      userHasAdLite={user?.user?.adLite}
    />
  );
}
