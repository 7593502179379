"use client";

import moment from "moment/moment";
import React, { JSX, useEffect, useState } from "react";
import Card from "rivals/components/shared/Community/Card";
import styles from "rivals/components/shared/Community/Community.module.scss";
import { Props } from "rivals/components/shared/Community/ForumsFeedCard/types";

export function ForumsFeedCard({
  feed,
  isWidget,
  isDarkMode
}: Props): JSX.Element {
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(`${moment(feed.createdAt).format("L")} ${feed.creatorUsername}`);
  }, [feed.createdAt, feed.creatorUsername]);

  return (
    <div className={styles.cardContainer}>
      <Card
        avatar={feed.avatar}
        description={feed.title}
        isDarkMode={isDarkMode}
        isNationals={false}
        isWidget={isWidget}
        replyCount={feed.replyCount}
        title={title}
        updatedAt={feed.updatedAt}
        updatedBy={feed.lastPostUsername}
        url={feed.url}
        viewCount={feed.viewCount}
      />
    </div>
  );
}
