import { TeamSelectionItem } from "rivals/components/shared/TeamSelector/TeamSelector.types";
import { Site } from "rivals/shared/interfaces/Site";

export function getTeamSelectionItemsFromSites(
  sites: Site[]
): TeamSelectionItem[] {
  return sites
    ? sites.map((site: Site) => ({
        teamId: site.id,
        teamLogo: site.teamLogo,
        teamName: site.friendlyName,
        teamSiteColor: site.primaryColor,
        teamUrl: site.baseUrl
      }))
    : [];
}

export function getCollegesFromTeamSelectionItems(
  items: TeamSelectionItem[]
): TeamSelectionItem[] {
  return items
    ? items.filter(site => site.teamName && !site.teamName.includes("HS"))
    : [];
}

export function getHighSchoolsFromTeamSelectionItems(
  items: TeamSelectionItem[]
): TeamSelectionItem[] {
  return items
    ? items.filter(site => site.teamName && site.teamName.includes("HS"))
    : [];
}
