import moment from "moment-timezone";
import { RIVALS_TIMEZONE } from "rivals/shared/constants";
import {
  currentGameStatuses,
  GameStatuses,
  nextGameStatuses,
  previousGameStatuses
} from "rivals/shared/interfaces/GameScore";
import { WIDGET_SIZE } from "rivals/shared/interfaces/Widgets";
import commonStyles from "rivals/styles/exports.module.scss";

/**
 * isGameCurrent determines whether a game is active based on its status
 * @param status string, game status based on yahoo DB
 * @returns boolean true/false
 */
export const isGameCurrent = (status: GameStatuses): boolean => {
  return currentGameStatuses.includes(status);
};
/**
 * isGameFinal determines whether a game is over or forfeited
 * @param status string, game status based on yahoo DB
 * @returns boolean true/false
 */
export const isGameFinal = (status: GameStatuses): boolean => {
  return previousGameStatuses.includes(status);
};
/**
 * isGameUpcoming determines whether a game has yet to be played
 * @param status string, game status based on yahoo DB
 * @returns boolean true/false
 */
export const isGameUpcoming = (status: GameStatuses): boolean => {
  return nextGameStatuses.includes(status);
};
/**
 * formatGameStartingDate formats the start time for schedule games
 * @param startTime string, yahoo provided string when a game starts
 * @param status string, yahoo provided game status (in progress, upcoming, or completed enums)
 * @returns a formatted date string with either a time zone or a FINAL marker
 */
export const formatGameStartingDate = (
  startTime: string,
  status: GameStatuses
): string => {
  if (!startTime) {
    return "TBA";
  }
  const timeZone = moment.tz.guess() || RIVALS_TIMEZONE;
  const startDate = moment.tz(startTime, timeZone);
  const timeZoneAbbr = moment().tz(timeZone).format("z");

  if (isGameUpcoming(status)) {
    return `${startDate.format("ddd MM/DD - h:mm A")} ${timeZoneAbbr}`;
  } else {
    return `FINAL - ${startDate.format("ddd MM/DD")}`;
  }
};

/**
 * Returns a subset of the input data array based on the specified size and device type.
 * @template T - The type of the elements in the data array.
 * @param {T[]} data - The input data array.
 * @param {WIDGET_SIZE} size - The size of the widget.
 * @returns {T[]} - A subset of the input data array based on the specified size and device type.
 */
export const getWidgetDataLengthLimit = <T>(
  data: T[],
  size: WIDGET_SIZE
): T[] => {
  return data.slice(0, size);
};

/**
 * Appends an opacity value to allow more transparent color.
 * Assumes hex in format as Setting.primary_color
 * Default '1A' corresponds to 10% opacity in hex
 * Returns a linear gradient
 *
 * @param schoolColor hex value of the color to transparencify, should be length of 6
 * @param opacityValue two hex values corresponding to the desired opacity
 */
export const fadeBackground = (
  schoolColor: string | null,
  darkMode: boolean = false,
  opacityValue: string = "1A"
): string => {
  if (darkMode) {
    opacityValue = "33";
    const gradientColor =
      schoolColor?.length == 6 // eslint-disable-line no-magic-numbers
        ? `#${schoolColor}${opacityValue}`
        : commonStyles.stone50;

    return `linear-gradient(to right, ${gradientColor} 15%, transparent 100%)`;
  }

  const gradientColor =
    schoolColor?.length == 6 // eslint-disable-line no-magic-numbers
      ? `#${schoolColor}${opacityValue}`
      : commonStyles.eyeBlack10;

  return `linear-gradient(to right,${commonStyles.white} 15%, ${gradientColor} 100%)`;
};
