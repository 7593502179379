"use client";

import { JSX, ReactNode } from "react";
import ErrorBoundary from "rivals/components/app/ErrorBoundary";

export default function ErrorBoundaryWrapper({
  children,
  fallback = null
}: {
  children: ReactNode;
  fallback?: ReactNode;
}): JSX.Element {
  return <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>;
}
