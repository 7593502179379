"use client";

import { setData } from "rivals/shared/utils/qa";
import { Props } from "./types";

const DISTROSCALE_DATA_PHILTER = "distro-scale";

// TODO: https://griosf.atlassian.net/browse/RVLS-7770
export default function DistroScaleAd({
  shouldRenderAdsForUser
}: Props): React.JSX.Element | null {
  if (!shouldRenderAdsForUser) {
    return null;
  }

  return (
    <div className="distro_ad" {...setData(DISTROSCALE_DATA_PHILTER)}>
      <div className="video-ad-wrapper">
        <div className="video-container" id="ds_default_anchor"></div>
      </div>
    </div>
  );
}
