import classNames from "classnames";
import { setData } from "rivals/shared/utils/qa";
import styles from "./TexturedBackground.module.scss";
import Props from "./types";

const TexturedBackground = ({
  baseColor,
  children,
  className,
  style = {},
  variant = 1
}: Props): React.JSX.Element => {
  const backgroundImageClass = styles[`variant${variant}`];

  return (
    <div
      className={classNames(
        styles.texturedBackground,
        backgroundImageClass,
        className
      )}
      style={{ ...style, backgroundColor: baseColor }}
      {...setData("textured-background")}
    >
      {children}
    </div>
  );
};

export default TexturedBackground;
