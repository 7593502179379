import { useEffect } from "react";

/**
 * Render ad slot when Benji is ready
 * Prevents race condition when ad slot is rendered after Benji start() is called
 */
export default function useBenjiRender({
  positionConfig,
  isBenjiReady
}: {
  isBenjiReady: boolean;
  positionConfig: BenjiConfigPositions;
}): void {
  useEffect(() => {
    if (isBenjiReady && window.benji?.render) {
      window.benji.render(positionConfig);
    }
  }, [isBenjiReady, positionConfig]);
}
