export default function Eye(): JSX.Element {
  return (
    <svg
      fill="none"
      height="13"
      viewBox="0 0 13 13"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7591_105722)" id="mdi:eye">
        <path
          d="M6.47654 4.875C6.06516 4.875 5.67062 5.0462 5.37973 5.35095C5.08883 5.6557 4.92541 6.06902 4.92541 6.5C4.92541 6.93098 5.08883 7.3443 5.37973 7.64905C5.67062 7.9538 6.06516 8.125 6.47654 8.125C6.88793 8.125 7.28246 7.9538 7.57336 7.64905C7.86425 7.3443 8.02767 6.93098 8.02767 6.5C8.02767 6.06902 7.86425 5.6557 7.57336 5.35095C7.28246 5.0462 6.88793 4.875 6.47654 4.875ZM6.47654 9.20833C5.7909 9.20833 5.13334 8.92299 4.64852 8.41508C4.16369 7.90717 3.89132 7.21829 3.89132 6.5C3.89132 5.78171 4.16369 5.09283 4.64852 4.58492C5.13334 4.07701 5.7909 3.79167 6.47654 3.79167C7.16218 3.79167 7.81974 4.07701 8.30457 4.58492C8.78939 5.09283 9.06176 5.78171 9.06176 6.5C9.06176 7.21829 8.78939 7.90717 8.30457 8.41508C7.81974 8.92299 7.16218 9.20833 6.47654 9.20833ZM6.47654 2.4375C3.89132 2.4375 1.68355 4.12208 0.789062 6.5C1.68355 8.87792 3.89132 10.5625 6.47654 10.5625C9.06176 10.5625 11.2695 8.87792 12.164 6.5C11.2695 4.12208 9.06176 2.4375 6.47654 2.4375Z"
          fill="#C9C9C9"
          id="Vector"
        />
      </g>
      <defs>
        <clipPath id="clip0_7591_105722">
          <rect
            fill="white"
            height="13"
            transform="translate(0.273438)"
            width="12.409"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
