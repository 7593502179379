"use client";

import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Avatar from "rivals/components/shared/Avatar";
import ButtonWrapper from "rivals/components/shared/Button";
import CollegeLogo from "rivals/components/shared/CollegeLogo";
import strings from "rivals/components/shared/Community/strings";
import Eye from "rivals/components/shared/Icons/Eye";
import Message from "rivals/components/shared/Icons/Message";
import Link from "rivals/components/shared/Link";
import { setData } from "rivals/shared/utils/qa";
import styles from "./Card.module.scss";
import { Props } from "./types";

const philter = "community-card";

const { nationalsCardButtonText } = strings;

export default function Card({
  avatar,
  isDarkMode = false,
  title,
  description,
  isNationals = false,
  isWidget = false,
  updatedAt = "",
  updatedBy = "",
  url,
  viewCount,
  replyCount
}: Props): JSX.Element {
  const [lastUpdated, setLastUpdated] = useState("");

  useEffect(() => {
    setLastUpdated(moment(updatedAt).fromNow());
  }, [updatedAt]);

  return (
    <div
      className={classNames(styles.card, {
        [styles.dark]: isDarkMode,
        [styles.widget]: isWidget
      })}
    >
      <Link className={styles.link} href={url} />
      <div className={styles.avatar}>
        {isNationals ? (
          <CollegeLogo collegeLogo={avatar} collegeName={title} logoSize={40} />
        ) : (
          <Avatar alt={title} size={40} url={avatar} />
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.title} {...setData(philter, "title")}>
          {title}
        </div>
        <div
          className={styles.description}
          {...setData(philter, "description")}
        >
          {description}
        </div>
        <div className={styles.footer}>
          {isNationals ? (
            <ButtonWrapper
              buttonText={nationalsCardButtonText}
              dark={isDarkMode}
              data={philter}
              inverted
              size={"extraSmall"}
            />
          ) : (
            <span className={styles.date}>{`${lastUpdated} ${updatedBy}`}</span>
          )}
          <div
            {...setData(philter, "view-details")}
            className={styles.messageContainer}
          >
            {!!viewCount && (
              <div className={styles.iconWrapper}>
                <div className={styles.viewIcon}>
                  <Eye />
                </div>
                {viewCount}
              </div>
            )}
            <div className={styles.iconWrapper}>
              <div className={styles.messageIcon}>
                <Message />
              </div>
              {replyCount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
