import classNames from "classnames";
import React from "react";
import Avatar from "rivals/components/shared/Avatar";
import Link from "rivals/components/shared/Link";
import Stars from "rivals/components/shared/Stars";
import widgetStyles from "rivals/components/shared/Widgets/Widgets.module.scss";
import { buildRefLink } from "rivals/services/referral";
import styles from "./CommitmentRow.module.scss";
import { Props } from "./types";

function CommitmentRow({
  darkMode,
  externalLink,
  philter,
  prospect,
  reflink
}: Props): React.JSX.Element {
  const {
    athleteUrl,
    firstName,
    id,
    lastName,
    positionAbbreviation,
    profileImageUrl,
    stars
  } = prospect;

  return (
    <>
      <div className={styles.athleteColumn}>
        <Link
          data={`${philter}-avatar-profile-${id}`}
          href={buildRefLink(athleteUrl, reflink)}
          target={externalLink ? "_blank" : undefined}
        >
          <Avatar alt="in commitments widget" size={40} url={profileImageUrl} />
        </Link>
        <Link
          className={classNames(widgetStyles.linkOverride, {
            [widgetStyles.darkMode]: darkMode
          })}
          data={`${philter}-profile-${id}`}
          href={buildRefLink(athleteUrl, reflink)}
          target={externalLink ? "_blank" : undefined}
        >
          <div>{firstName}</div>
          <div className={styles.lastName}>{lastName}</div>
        </Link>
      </div>
      <div className={styles.singleColumn}>{positionAbbreviation}</div>
      <div className={styles.singleColumn}>
        <Stars className={styles.athleteStars} numStars={stars} />
      </div>
    </>
  );
}

export default CommitmentRow;
