// Required due to Fresnel
"use client";

import ButtonWrapper from "rivals/components/shared/Button";
import RankUp from "rivals/components/shared/Icons/RankUp";
import MediaWrapper from "rivals/components/shared/Media";
import TexturedBackground from "rivals/components/shared/TexturedBackground";
import { MEDIA_BREAKPOINTS } from "rivals/media";
import { PATHS } from "rivals/shared/constants";
import { setData } from "rivals/shared/utils/qa";
import styles from "./PromoUpsell.module.scss";
import strings from "./strings";
import Props from "./types";

const PromoUpsell = ({
  className,
  collegeBaseColor,
  collegeName,
  style
}: Props): React.JSX.Element => {
  const philter = "upsell-cta";

  const isCollegeThemed = !!collegeBaseColor;

  const renderTitleEl = (breakpoint: string | null = null): JSX.Element => {
    const titleClassName =
      breakpoint === MEDIA_BREAKPOINTS.SM ? styles.titleSm : styles.title;

    const titleEl = strings.title.map((t, index) => (
      <span className={styles.titleItem} key={index}>
        {t}
      </span>
    ));

    return <h1 className={titleClassName}>{titleEl}</h1>;
  };

  const renderSubtitleEl = (
    breakpoint: string | null = null
  ): JSX.Element | null => {
    if (!collegeName) {
      return null;
    }

    const subtitleClassName =
      breakpoint === MEDIA_BREAKPOINTS.SM ? styles.subtitleSm : styles.subtitle;
    return (
      <h2 {...setData(philter, "subtitle")} className={subtitleClassName}>
        {strings.subtitle.replace("${collegeName}", collegeName)}
      </h2>
    );
  };

  const renderListEls = (breakpoint: string | null = null): JSX.Element => {
    const listClassName =
      breakpoint === MEDIA_BREAKPOINTS.SM ? styles.listSm : styles.list;

    const listEls = strings.list.map(({ title, description }, index) => {
      return (
        <div className={styles.listItem} key={index}>
          <div className={styles.listItemHeading}>
            <RankUp fill={styles.bone} height="14.3" width="11" />
            <h3>{title}</h3>
          </div>
          <p>{description}</p>
        </div>
      );
    });

    return <div className={listClassName}>{listEls}</div>;
  };

  return (
    <TexturedBackground
      baseColor={collegeBaseColor}
      className={className}
      style={style}
      variant={2}
    >
      <div className={styles.component}>
        <div className={styles.content}>
          <MediaWrapper at={MEDIA_BREAKPOINTS.SM}>
            <>
              {renderTitleEl(MEDIA_BREAKPOINTS.SM)}
              {renderSubtitleEl(MEDIA_BREAKPOINTS.SM)}
              {renderListEls(MEDIA_BREAKPOINTS.SM)}
            </>
          </MediaWrapper>
          <MediaWrapper greaterThan={MEDIA_BREAKPOINTS.SM}>
            <>
              {renderTitleEl()}
              {renderSubtitleEl()}
              {renderListEls()}
            </>
          </MediaWrapper>
          <ButtonWrapper
            backgroundColor={isCollegeThemed ? styles.bone : undefined}
            buttonText={strings.ctaButtonText}
            fullWidth
            {...setData(philter, "cta-button")}
            url={PATHS.SIGN_UP}
          />
        </div>
      </div>
    </TexturedBackground>
  );
};

export default PromoUpsell;
