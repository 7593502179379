"use client";

import Select from "antd/lib/select";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { TEAM_RANKING_TABS } from "rivals/components/pages/TeamRankings/constants";
import ButtonWrapper from "rivals/components/shared/Button";
import CollegeLogo from "rivals/components/shared/CollegeLogo";
import Chevron from "rivals/components/shared/Icons/Chevron";
import ExternalLinkIcon from "rivals/components/shared/Icons/ExternalLinkIcon";
import RivalsSquareIcon from "rivals/components/shared/Icons/RivalsSquareIcon";
import { variantFillColor } from "rivals/components/shared/Icons/iconUtils";
import RivalsLink from "rivals/components/shared/Link";
import EmptyStateMessage from "rivals/components/shared/Widgets/EmptyStateMessage";
import WidgetHeader from "rivals/components/shared/Widgets/WidgetHeader";
import WidgetLayout from "rivals/components/shared/Widgets/WidgetLayout";
import widgetStyles from "rivals/components/shared/Widgets/Widgets.module.scss";
import {
  fadeBackground,
  getWidgetDataLengthLimit
} from "rivals/components/shared/Widgets/utils";
// eslint-disable-next-line no-restricted-imports
import { configureClientComponentApi } from "rivals/services/configureClientComponentApi";
import { GetTeamRankingsResponse } from "rivals/services/container";
import { buildRefLink } from "rivals/services/referral";
import {
  NATIONAL_SITE_IDENTIFIERS,
  PATHS,
  RANK,
  ROTATE_90,
  SCHOOL,
  SEE_MORE,
  SEE_MORE_ON_RIVALS,
  TEAM_RANKING_TYPES
} from "rivals/shared/constants";
import { TeamRanking } from "rivals/shared/interfaces/TeamRanking";
import { WIDGET_SIZE } from "rivals/shared/interfaces/Widgets";
import { setData } from "rivals/shared/utils/qa";
import styles from "./TeamRankings.module.scss";
import strings from "./strings";
import { Props } from "./types";

function TeamRankings({
  buttonFullWidth = false,
  conference = "all-teams",
  darkMode = false,
  data,
  externalLink,
  primaryColor,
  reflink,
  removeBorder = false,
  showTopBar = true,
  size = WIDGET_SIZE.FULL_SIZE,
  selectedSiteShortname,
  sponsor,
  sport,
  type,
  useYearOverride,
  width,
  year
}: Props): React.JSX.Element {
  // TODO: https://griosf.atlassian.net/browse/RVLS-9529 reenable other basketball team ranking tabs
  if (sport === "basketball") {
    type = TEAM_RANKING_TYPES.RECRUITING;
  }

  const { emptyStateMessage, philter } = strings;
  const [currentType, setCurrentType] = useState<TEAM_RANKING_TYPES>(type);
  const [teamRankData, setRankData] = useState<TeamRanking[]>(data || []);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const filteredTeamRankingsData = getWidgetDataLengthLimit(teamRankData, size);
  const datPhilterLinkMap = {
    [TEAM_RANKING_TYPES.COMPREHENSIVE]: "",
    [TEAM_RANKING_TYPES.RECRUITING]: "commitments",
    [TEAM_RANKING_TYPES.TRANSFER]: "transfer-tracker"
  };
  const linkPhilter = datPhilterLinkMap[currentType];

  if (useYearOverride) {
    // TODO: un-hardcode year: https://griosf.atlassian.net/browse/RVLS-9307
    year = {
      [TEAM_RANKING_TYPES.COMPREHENSIVE]: "2024",
      [TEAM_RANKING_TYPES.RECRUITING]: "2025",
      [TEAM_RANKING_TYPES.TRANSFER]: "2024"
    }[currentType];
  }

  const buttonUrl =
    // TODO: https://griosf.atlassian.net/browse/RVLS-9529 reenable other basketball team ranking tabs
    sport === "basketball"
      ? PATHS.TEAM_RANKINGS.replace("[year]", year)
          .replace("[conference]", conference)
          .replace("[sport]", sport)
          .replace("[type]", "recruiting")
      : PATHS.TEAM_RANKINGS.replace("[year]", year)
          .replace("[conference]", conference)
          .replace("[sport]", sport)
          .replace("[type]", currentType);
  const isNationalSite = NATIONAL_SITE_IDENTIFIERS.includes(
    selectedSiteShortname.toLocaleLowerCase()
  );

  const options = TEAM_RANKING_TABS.map(tab => {
    return {
      label: (
        <div className={styles.optionItem}>
          {tab.icon}
          <span
            className={styles.optionName}
          >{`${tab.key} Team Rankings`}</span>
        </div>
      ),
      value: tab.key
    };
  });

  const fetchData = useCallback(async (): Promise<void> => {
    const apiService = configureClientComponentApi();
    const response: GetTeamRankingsResponse = await apiService.getTeamRankings({
      conference: conference,
      sport: sport,
      type: currentType,
      year: year
    });
    setRankData(response.teamRankings);
  }, [conference, currentType, sport, year]);

  useEffect(() => {
    // Fetch data for type on change
    fetchData();
  }, [currentType, fetchData]);

  useEffect(() => {
    setCurrentType(type);
  }, [type]);

  const widgetHeader = (
    // TODO: https://griosf.atlassian.net/browse/RVLS-9529 reenable other basketball team ranking tabs
    <WidgetHeader
      actionIcon={
        sport === "football" ? (
          <Select
            className={styles.titleSelect}
            dropdownStyle={{ borderRadius: 0, padding: "4px 0" }}
            onChange={async (value: TEAM_RANKING_TYPES) => {
              setCurrentType(value);
            }}
            onDropdownVisibleChange={(open: boolean): void => {
              setSelectOpen(open);
            }}
            options={options}
            popupClassName={styles.rankingTypeSelectDropdown}
            size={"large"}
            suffixIcon={
              <Chevron
                animateRotation={true}
                fill={variantFillColor(darkMode)}
                rotate={selectOpen ? ROTATE_90 : -ROTATE_90}
              />
            }
            value={currentType}
            variant={"borderless"}
            {...setData(philter, "type-selector")}
          />
        ) : undefined
      }
      eyebrowText={currentType}
      title={"Team Rankings"}
      titleIcon={<RivalsSquareIcon type={currentType} />}
      year={year}
    />
  );

  const buildRow = (item: TeamRanking, index: number): React.JSX.Element => {
    const featuredSchool =
      item.siteName?.toLowerCase() === selectedSiteShortname?.toLowerCase();

    const collegeLogo = (
      <CollegeLogo
        collegeLogo={item.collegeLogo}
        collegeName={item.collegeName}
        logoSize={40}
        priority={index === 0}
      />
    );
    // Wrap college logo and name in link tags when collegeUrl is present
    const collegeLogoAndName = item.collegeUrl ? (
      <>
        <RivalsLink
          className={classNames(widgetStyles.linkOverride, {
            [widgetStyles.darkMode]: darkMode
          })}
          data={`${item.collegeId}-logo-${linkPhilter}`}
          href={buildRefLink(item.collegeUrl, reflink)}
          target={externalLink ? "_blank" : undefined}
        >
          {collegeLogo}
        </RivalsLink>
        <div>
          <RivalsLink
            className={classNames(widgetStyles.linkOverride, {
              [widgetStyles.darkMode]: darkMode
            })}
            data={`${item.collegeId}-${linkPhilter}`}
            href={buildRefLink(item.collegeUrl, reflink)}
            target={externalLink ? "_blank" : undefined}
          >
            <div className={styles.name}>{item.collegeName}</div>
          </RivalsLink>
        </div>
      </>
    ) : (
      <>
        {collegeLogo}
        <div className={styles.name}>{item.collegeName}</div>
      </>
    );

    return (
      <li
        aria-label={`data-row-${item.collegeName}`}
        className={classNames(widgetStyles.dataRow, styles.dataRow, {
          [widgetStyles.darkModeRow]: darkMode
        })}
        key={item?.collegeId}
        role="listitem"
        style={{
          backgroundImage: featuredSchool
            ? fadeBackground(item.collegeColor, darkMode)
            : undefined
        }}
        {...setData(philter, `college-row-${item.collegeId}`)}
      >
        <div
          className={classNames(widgetStyles.ordinality, {
            [widgetStyles.darkMode]: darkMode
          })}
        >
          {item?.[currentType]?.ordinality}
        </div>
        {collegeLogoAndName}
      </li>
    );
  };

  return (
    <WidgetLayout
      button={
        <ButtonWrapper
          buttonText={externalLink ? SEE_MORE_ON_RIVALS : SEE_MORE}
          className={classNames(
            {
              [widgetStyles.darkMode]: darkMode,
              [widgetStyles.smallButton]: buttonFullWidth
            },
            widgetStyles.externalIcon
          )}
          dark={darkMode}
          data={philter}
          fullWidth
          icon={externalLink ? <ExternalLinkIcon fill="none" /> : undefined}
          inverted
          shouldAddNewTab={externalLink}
          url={buildRefLink(buttonUrl, reflink)}
        />
      }
      buttonFullWidth={buttonFullWidth}
      darkMode={darkMode}
      removeBorder={removeBorder}
      sponsor={sponsor}
      title={widgetHeader}
      topBorderColor={
        showTopBar && !isNationalSite ? `#${primaryColor}` : undefined
      }
      width={width}
    >
      <div>
        <div
          className={classNames(styles.titleRow, widgetStyles.titleRow)}
          {...setData(philter, "title")}
        >
          <div className={widgetStyles.centerTitle}>{RANK}</div>
          <div>{SCHOOL}</div>
        </div>
        {teamRankData.length > 0 && (
          <ul className={styles.teamRankingsList}>
            {filteredTeamRankingsData.map(
              (item: TeamRanking, index: number) => {
                return buildRow(item, index);
              }
            )}
          </ul>
        )}
        {!teamRankData.length && (
          <EmptyStateMessage
            darkMode={darkMode}
            message={emptyStateMessage}
            philter={philter}
          />
        )}
      </div>
    </WidgetLayout>
  );
}

export default TeamRankings;
