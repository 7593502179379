"use client";

import classNames from "classnames";
import React from "react";
import styles from "rivals/components/shared/Community/Community.module.scss";
import { TopSiteCard } from "rivals/components/shared/Community/TopSitesCard";
import { Props } from "rivals/components/shared/Community/TopSitesCards/types";
import strings from "rivals/components/shared/Community/strings";
import { MAX_MOBILE_CARD_NUM } from "rivals/components/shared/Community/utils";
import MediaWrapper from "rivals/components/shared/Media";
import TeamSelector from "rivals/components/shared/TeamSelector";
import { MEDIA_BREAKPOINTS } from "rivals/media";

const { nationalsTitle, nationalsTeamSelectorTitle } = strings;

export default function TopSitesCards({
  isNationals,
  isDarkMode,
  isWidget,
  topForums,
  colleges,
  highSchools
}: Props): JSX.Element {
  const numberDesktopCards = topForums.length + 1;

  const mobileCards = topForums
    .slice(0, MAX_MOBILE_CARD_NUM)
    .map(forum => (
      <TopSiteCard
        forum={forum}
        isDarkMode={isDarkMode}
        isNationals={isNationals}
        isWidget={isWidget}
        key={forum.id}
      />
    ));

  const desktopCards = topForums
    .slice(0, numberDesktopCards)
    .map(forum => (
      <TopSiteCard
        forum={forum}
        isDarkMode={isDarkMode}
        isNationals={isNationals}
        isWidget={isWidget}
        key={forum.id}
      />
    ));

  return (
    <>
      <div className={styles.title}>{nationalsTitle}</div>
      <MediaWrapper lessThan={MEDIA_BREAKPOINTS.MD}>
        <div className={classNames(styles.cardsContainer)}>{mobileCards}</div>
      </MediaWrapper>
      <MediaWrapper greaterThanOrEqual={MEDIA_BREAKPOINTS.MD}>
        <div className={classNames(styles.cardsContainer, styles.twoRow)}>
          {desktopCards}
        </div>
      </MediaWrapper>
      <div className={styles.actionContainer}>
        <div className={styles.teamSelectContainer}>
          <div className={styles.actionTitle}>{nationalsTeamSelectorTitle}</div>
          <div className={styles.teamSelector}>
            <TeamSelector colleges={colleges} highSchools={highSchools} />
          </div>
        </div>
      </div>
    </>
  );
}
