export default {
  conference: "Conference Record",
  leagueScheduleUrl: (sport: string): string =>
    `https://sports.yahoo.com/college-${sport}/scoreboard/`,
  notAvailable: (year?: number): string => {
    return year ? `${year} schedule not available.` : "Schedule not available";
  },
  overall: "Overall Record",
  sectionTitles: {
    finished: "Finished",
    live: "Live",
    upcoming: "Upcoming"
  },
  widgetTitle: "Scores / Schedule"
};
