"use client";

import React, { JSX } from "react";
import Card from "rivals/components/shared/Community/Card";
import styles from "rivals/components/shared/Community/Community.module.scss";
import { Props } from "rivals/components/shared/Community/TopSitesCard/types";
import strings from "rivals/components/shared/Community/strings";

const { fansOf } = strings;

export function TopSiteCard({
  forum,
  isDarkMode,
  isNationals,
  isWidget
}: Props): JSX.Element {
  return (
    <div className={styles.cardContainer}>
      <Card
        avatar={forum.teamLogo}
        description={forum.forumTagLine}
        isDarkMode={isDarkMode}
        isNationals={isNationals}
        isWidget={isWidget}
        replyCount={forum.replyCount ?? 1}
        title={`${fansOf} ${forum.friendlyName}`}
        url={forum.forumUrl}
      />
    </div>
  );
}
