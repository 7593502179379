import classNames from "classnames";
import { useState } from "react";
import Select from "rivals/components/shared/Form/Select";
import {
  SelectGroupOption,
  SelectOption,
  SelectVariant
} from "rivals/components/shared/Form/Select/types";
import TeamOption from "./TeamOption";
import styles from "./TeamSelector.module.scss";
import {
  GroupLabelProps,
  TeamSelectionItem,
  TeamSelectorProps
} from "./TeamSelector.types";

const TeamSelector = ({
  className = "",
  colleges,
  highSchools,
  wrapperClassName = ""
}: TeamSelectorProps): React.JSX.Element => {
  const [value, setValue] = useState<string>();

  const onChange = (newValue: string): void => {
    setValue(newValue);
  };

  const options = (teams: TeamSelectionItem[]): SelectOption<string>[] => {
    return teams.map(team => ({
      name: <TeamOption {...team} />,
      value: team.teamName
    }));
  };

  const GroupLabel = ({ name }: GroupLabelProps): React.JSX.Element => (
    <div className={styles.groupLabel}>{name}</div>
  );

  const groupOptions = (): SelectGroupOption<string>[] => {
    const groupOptions: SelectGroupOption<string>[] = [];

    if (colleges) {
      groupOptions.push({
        children: options(colleges),
        name: <GroupLabel name={"Colleges"} />
      });
    }

    if (highSchools) {
      groupOptions.push({
        children: options(highSchools),
        name: <GroupLabel name={"High Schools"} />
      });
    }

    return groupOptions;
  };

  return (
    <div className={styles.container}>
      <Select
        allowClear
        className={classNames(className, styles.teamSelector)}
        data="team"
        dropdownClassName={styles.groupedOptions}
        filterOption
        groupOptions={groupOptions()}
        onChange={onChange}
        optionFilterProp="value"
        placeholder="Select your team"
        showSearch
        value={value}
        variant={SelectVariant.DARK}
        wrapperClassName={wrapperClassName}
      />
    </div>
  );
};

export default TeamSelector;
