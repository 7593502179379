export default {
  ctaButtonText: "Subscribe to Rivals",
  list: [
    {
      description:
        "Get Premium breaking news from the largest network of experts.",
      title: "Hear it first"
    },
    {
      description:
        "Champion your team in fan communities and Spy on Your Rivals.",
      title: "Say your piece"
    },
    {
      description:
        "Unlock extensive athlete data, Rivals250 rankings, compete with FanFutureCast predictions, and more.",
      title: "Dominate with stats"
    }
  ],
  subtitle: "Join the ${collegeName} Community.",
  title: ["Go Big. ", "Go Premium."]
};
