import type { ContentCardItem } from "rivals/shared/interfaces/Content";
import type { Site } from "rivals/shared/interfaces/Site";

export enum CONTENT_CARD_VARIANTS {
  HIGHLIGHTED = "highlighted",
  COMMON = "common",
  SMALL_COMMON = "smallCommon",
  WIDGET = "widget"
}

export interface Props extends Partial<ContentCardItem> {
  /**
   * String className to override the card's styles
   */
  className?: string;
  /**
   * Flag for color palette
   */
  darkMode?: boolean;
  /**
   * QA HTML elements selector, should be specific to the page or the section.
   */
  philter: string;
  /**
   * The current site (via subdomain) the user is viewing
   */
  site: Site;
  /**
   * Each variant styles the card in a different way, see the stories below to compare.
   */
  variant: CONTENT_CARD_VARIANTS;
}
