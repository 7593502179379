"use client";

import Script from "next/script";
import { useEffect } from "react";
import { useGetRenderAdsForUser } from "rivals/shared/app/client";

function setProps(spaceId: number | undefined): void {
  if (!spaceId) {
    return;
  }

  window._comscore = window._comscore || [];
  window._comscore.push({
    c1: "2",
    c2: "7241469",
    c5: `${spaceId}`,
    c7: window.location.href,
    /* eslint-disable @typescript-eslint/naming-convention */
    cs_fpdm: "*null",
    cs_fpdt: "*null",
    cs_fpid: "*null",
    cs_fpit: "*null"
    /* eslint-enable @typescript-eslint/naming-convention */
  });
}

function Comscore(): JSX.Element | null {
  const data = useGetRenderAdsForUser();
  const spaceId = data.adSettings?.spaceId;
  const enabled =
    spaceId && process.env.NEXT_PUBLIC_COMSCORE_ENABLED === "true";

  useEffect(() => {
    if (enabled) {
      setProps(spaceId);
    }
  }, [spaceId, enabled]);

  if (!enabled) {
    return null;
  }

  return (
    <>
      <Script async={true} src="https://s.yimg.com/cx/vzm/cs.js" />
      <noscript>
        <img
          alt=""
          src={`https://sb.scorecardresearch.com/p?c1=2&c2=7241469&c7=${window.location.href}&c5=${spaceId}&cv=2.0&cj=1&cs_ucfr=0`}
        />
      </noscript>
    </>
  );
}

export default Comscore;
