import type { SelectProps } from "antd";
import { ReactNode } from "react";

export type OptionValue = string | number;

export interface BaseOptionType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
  className?: string;
  disabled?: boolean;
  title?: string;
}

export interface DefaultOptionType extends BaseOptionType {
  children?: Omit<DefaultOptionType, "children">[];
  label?: React.ReactNode;
  value?: string | number | null;
}

export enum SelectVariant {
  DEFAULT = "default",
  BORDER_NONE = "border-none",
  BORDER_ROUND = "border-round",
  BLUE_WITH_BOTTOM_BORDER = "blue-with",
  DARK = "dark"
}

export type FilterFunc<OptionType> = (
  inputValue: string,
  option?: OptionType
) => boolean;

// Most props are documented here https://ant.design/components/select
export interface Props<
  T extends OptionValue,
  OptionType extends BaseOptionType = DefaultOptionType
> {
  /** Shows an icon to clear the selected option */
  allowClear?: boolean;
  /** Label used for accessibility */
  ariaLabel?: string;
  /** Class name passed to ant design select */
  className?: string;
  /** QA Philter used to find the element in testing. "-select" is appended to this value */
  data?: string;
  /** Initial selected option */
  defaultValue?: T;
  /** Whether select is disabled */
  disabled?: boolean;
  /** The className of dropdown menu */
  dropdownClassName?: string;
  /** Adds a red border for error state */
  feedback?: boolean;
  /** If true, filter options by input, if function, filter options against it.  */
  filterOption?: boolean | FilterFunc<OptionType>;
  /** Sort function for search options sorting */
  filterSort?: (optionA: OptionType, optionB: OptionType) => number;
  groupOptions?: SelectGroupOption<T>[];
  /** Id for field */
  id?: string;
  /** Customize selected label render  */
  labelRender?: ((props: SelectProps) => ReactNode) | undefined;
  /** Control the height of the popup in pixels */
  listHeight?: number;
  /** Called when the select value changes */
  onChange: (value: T) => void;
  /** Called when clear */
  onClear?: () => void;
  /** Called when dropdown visibility is changed */
  onOpen?: (open: boolean) => void;
  /** Which prop value of option will be used for filter if filterOption is true. */
  optionFilterProp?: string;
  /** Select options. Will get better perf than jsx definition */
  options?: SelectOption<T>[];
  /** Placeholder of select */
  placeholder?: string;
  /** Whether select is searchable */
  showSearch?: boolean;
  /** Current selected option (considered as a immutable array) */
  value?: T;
  /** Style option of the select */
  variant?: SelectVariant;
  /** Width in px applied to select container */
  width?: string;
  /** Class name applied to div wrapping Select */
  wrapperClassName?: string;
}

export interface SelectElementProps<T extends OptionValue> {
  disabled?: boolean;
  name: string | JSX.Element;
  value: T;
}

export interface SelectOption<T extends OptionValue> {
  disabled?: boolean;
  name: string | JSX.Element;
  value: T;
}

export interface SelectGroupElementProps<T extends OptionValue> {
  children: SelectOption<T>[];
  idx: number;
  name: JSX.Element;
}

export interface SelectGroupOption<T extends OptionValue> {
  children: SelectOption<T>[];
  name: JSX.Element;
}
