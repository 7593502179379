"use client";

import classNames from "classnames";
import { JSX, useMemo } from "react";
import { isMobileOnly } from "react-device-detect";
import styles from "rivals/components/shared/Ads/Ads.module.scss";
import useBenjiRender from "rivals/components/shared/Ads/Benji/BenjiRefresh/useBenjiRender";
import {
  DESKTOP_MID_CENTER_PATH,
  LDRB_AD_DIMS,
  MOBILE_MID_CENTER_PATH,
  REGION,
  WFPAD_AD_DIMS
} from "rivals/components/shared/Ads/Benji/constants";
import { buildLocationStringWithCount } from "rivals/components/shared/Ads/Benji/utils";
import type { Props } from "rivals/components/shared/Ads/types";
import commonStrings from "rivals/shared/strings/common";
import { setData } from "rivals/shared/utils/qa";

const LDRB_ID = "boxLDRB";
const LDRB_SLOT_NAME = "ldrb";

/**
 * LDRB (Leaderboard) ad slot
 *
 * Desktop - Supports LDRB
 *
 * Mobile - Supports WFPAD
 */
export default function LDRB({
  adStyles,
  forceAdToFront = false,
  isBenjiReady = false,
  location,
  locationCount,
  slotNumber,
  userHasAdLite
}: Props): JSX.Element | null {
  const id = `${LDRB_ID}${slotNumber && slotNumber > 1 ? slotNumber : ""}`;
  const slotName = `${LDRB_SLOT_NAME}${
    slotNumber && slotNumber > 1 ? slotNumber : ""
  }`;

  // Define ad position configuration
  const positionConfig = useMemo(
    () => ({
      [id]: {
        id,
        kvs: { loc: buildLocationStringWithCount(location, locationCount) },
        path: isMobileOnly ? MOBILE_MID_CENTER_PATH : DESKTOP_MID_CENTER_PATH,
        region: REGION,
        size: isMobileOnly ? [WFPAD_AD_DIMS] : [LDRB_AD_DIMS]
      }
    }),
    [id, location, locationCount]
  );

  useBenjiRender({ isBenjiReady, positionConfig });

  // There is currently no Ad Setting (from site settings) to disable this slot
  if (userHasAdLite) {
    return null;
  }

  return (
    <div className={styles.horizontalContainer} style={adStyles}>
      <div
        className={classNames(styles.adContainer, {
          [styles.forceAdToFront]: forceAdToFront
        })}
        id={id}
        {...setData(`banner-${slotName}-ad`)}
      >
        <span
          {...setData(slotName, "placeholder")}
          className={styles.adPlaceholder}
        >
          {commonStrings.advertisement}
        </span>
      </div>
    </div>
  );
}
