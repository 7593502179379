import styles from "./Icons.module.scss";
import type { IconProps } from "./iconPropsTypes";

const PremiumContent = ({
  height = "15",
  width = "26",
  fill = styles.bone,
  fillBg = styles.hoops
}: IconProps): React.JSX.Element => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 26 15"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Premium content</title>
    <rect fill={fillBg} height={height} width={width} />
    <path
      d="M10.205 11H8V4H16.4138L17.7454 5.13739V7.14988L16.4138 8.28726H14.8108L18 10.9996H15.2434L10.2054 6.71191V10.9996L10.205 11ZM10.205 5.74979V6.71232H15.54V5.74979H10.205Z"
      fill={fill}
    />
  </svg>
);

export default PremiumContent;
