"use client";

import classNames from "classnames";
import React, { JSX } from "react";
import ButtonWrapper from "rivals/components/shared/Button";
import styles from "rivals/components/shared/Community/Community.module.scss";
import { Props } from "rivals/components/shared/Community/CommunityWidget/types";
import { ForumsFeedCard } from "rivals/components/shared/Community/ForumsFeedCard";
import strings from "rivals/components/shared/Community/strings";
import { MAX_WIDGET_CARD_NUM } from "rivals/components/shared/Community/utils";
import WidgetHeader from "rivals/components/shared/Widgets/WidgetHeader";
import WidgetLayout from "rivals/components/shared/Widgets/WidgetLayout";
import widgetStyles from "rivals/components/shared/Widgets/Widgets.module.scss";

const { community } = strings;

export default function CommunityWidget({
  isDarkMode,
  removeBorder,
  feeds
}: Props): JSX.Element {
  const forumsFeedCards = feeds
    .slice(0, MAX_WIDGET_CARD_NUM)
    .map(feed => (
      <ForumsFeedCard
        feed={feed}
        isDarkMode={isDarkMode}
        isWidget
        key={feed.title}
      />
    ));

  return (
    <div
      className={classNames([styles.container, styles.widget], {
        [styles.dark]: isDarkMode
      })}
    >
      <WidgetLayout
        button={
          <ButtonWrapper
            buttonText={strings.siteCardsButtonText}
            className={classNames(styles.ctaButton, widgetStyles.smallButton)}
            dark={isDarkMode}
            fullWidth
            inverted
          />
        }
        buttonFullWidth
        darkMode={isDarkMode}
        removeBorder={removeBorder}
        title={<WidgetHeader title={community} />}
      >
        <>
          <div className={styles.padding} />
          {forumsFeedCards}
        </>
      </WidgetLayout>
    </div>
  );
}
