import {
  NATIONAL_SITE_IDENTIFIERS,
  ROOT_SITE_IDENTIFIER
} from "rivals/shared/constants";

/**
 * Determines whether a site is the Rivals national site (n.rivals) or not
 * @param siteName The name of thes site you are checking
 * @returns true if the site is the Rivals national site and false if it's not
 */
export const isNationalSite = (siteName: string | null): boolean => {
  return siteName?.toLocaleLowerCase() === ROOT_SITE_IDENTIFIER;
};

/**
 * Determines whether a site is the part of the rivals national umbrella or not
 * National umbrella sites are: n.rivals, basketballrecruiting, and portal
 *  as defined in the NATIONAL_SITE_IDENTIFIERS constant
 * @param siteName The name of the site you are checking
 * @returns true if the site is under the national umbrella and false if it's not
 */
export const isNationalUmbrellaSite = (siteName: string | null): boolean => {
  return (
    !!siteName && NATIONAL_SITE_IDENTIFIERS.includes(siteName.toLowerCase())
  );
};
