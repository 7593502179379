"use client";

import { JSX, useMemo } from "react";
import { isMobileOnly } from "react-device-detect";
import useBenjiRender from "components/shared/Ads/Benji/BenjiRefresh/useBenjiRender";
import styles from "rivals/components/shared/Ads/Ads.module.scss";
import {
  DESKTOP_MID_CENTER_PATH,
  DESKTOP_MID_RIGHT_PATH,
  DESKTOP_TOP_LEFT_PATH,
  DESKTOP_TOP_RIGHT_PATH,
  LREC_AD_DIMS,
  LREC_AD_DIMS_LG,
  LREC_AD_DIMS_SM,
  MID_CENTER,
  MID_RIGHT,
  MOBILE_MID_CENTER_PATH,
  REGION,
  TOP_LEFT,
  TOP_RIGHT
} from "rivals/components/shared/Ads/Benji/constants";
import { buildLocationStringWithCount } from "rivals/components/shared/Ads/Benji/utils";
import type { AdSizes, Props } from "rivals/components/shared/Ads/types";
import type { AdsSettings } from "rivals/shared/interfaces/Ads";
import commonStrings from "rivals/shared/strings/common";
import { setData } from "rivals/shared/utils/qa";

const LREC_ID = "boxLREC";
const LREC_SLOT_NAME = "lrec";

const SIZES: { [k in AdSizes]: (number | number[])[] } = {
  all: LREC_AD_DIMS,
  large: LREC_AD_DIMS_LG,
  small: LREC_AD_DIMS_SM
};

/**
 * LREC (Large Rectangle) ad slot
 *
 * Rendered in left and right rails on Desktop
 *
 * Rendered in the center of the page on Mobile
 *
 * Rendered in the body of an article
 *
 * Desktop & Mobile - Dimensions: 300x250 or 300x600
 */
export default function LREC({
  adStyles,
  adSettings,
  isBenjiReady = false,
  location,
  locationCount,
  size,
  slotNumber,
  userHasAdLite
}: Props): JSX.Element | null {
  const id = `${LREC_ID}${slotNumber && slotNumber > 1 ? slotNumber : ""}`;
  const slotName = `${LREC_SLOT_NAME}${
    slotNumber && slotNumber > 1 ? slotNumber : ""
  }`;

  let path: string;
  let adSetting: keyof AdsSettings | undefined;

  // TODO: https://griosf.atlassian.net/browse/RVLS-10193
  if (isMobileOnly) {
    path = MOBILE_MID_CENTER_PATH;
  } else {
    switch (location) {
      case TOP_LEFT:
        path = DESKTOP_TOP_LEFT_PATH;
        adSetting = "displayLrec1Leftrail";
        break;
      case TOP_RIGHT:
        path = DESKTOP_TOP_RIGHT_PATH;
        adSetting = "displayLrec1Leftrail";
        break;
      case MID_RIGHT:
        path = DESKTOP_MID_RIGHT_PATH;
        adSetting = "displayLrec2Module";
        break;
      case MID_CENTER:
      default:
        path = DESKTOP_MID_CENTER_PATH;
        adSetting = "displayLrec4Contentbody";
        break;
    }
  }

  // Define ad position configuration
  const positionConfig = useMemo(
    () => ({
      [id]: {
        id,
        kvs: {
          loc: buildLocationStringWithCount(location, locationCount)
        },
        path,
        region: REGION,
        size: size ? SIZES[size] : SIZES.all
      }
    }),
    [id, location, locationCount, path, size]
  );

  useBenjiRender({ isBenjiReady, positionConfig });

  // Respect ad settings for the slot or the user's ad lite subscription
  if ((adSettings && adSetting && !adSettings[adSetting]) || userHasAdLite) {
    return null;
  }

  return (
    <div className={styles.rectangularContainer} style={adStyles}>
      <div
        className={styles.adContainer}
        id={id}
        {...setData(`box-${slotName}-ad`)}
      >
        <span
          {...setData(slotName, "placeholder")}
          className={styles.adPlaceholder}
        >
          {commonStrings.advertisement}
        </span>
      </div>
    </div>
  );
}
