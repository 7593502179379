import Star from "rivals/components/shared/Icons/Star";
import TwoStars from "rivals/components/shared/Icons/TwoStars";
import { PATHS, TEAM_RANKING_TYPES } from "rivals/shared/constants";
import commonStyles from "rivals/styles/exports.module.scss";
import { TeamRankingTab } from "./types";

export function setTeamRankingPathVariables(
  year: number | string,
  conference: string,
  sport: string,
  type: string
): string {
  return `${PATHS.TEAM_RANKINGS.replace("[year]", year.toString())
    .replace("[type]", type.toString())
    .replace("[conference]", conference.toString())
    .replace("[sport]", sport)}`;
}

export const HEADER_LOGO_COLOR_MAP = {
  [TEAM_RANKING_TYPES.COMPREHENSIVE]: "black",
  [TEAM_RANKING_TYPES.TRANSFER]: "blue",
  [TEAM_RANKING_TYPES.RECRUITING]: "yellow"
} as { [index: string]: "black" | "yellow" | "blue" | undefined };

export const TEAM_RANKING_FORUMA_LINK_MAP = {
  [TEAM_RANKING_TYPES.COMPREHENSIVE]:
    PATHS.COMPREHENSIVE_TEAM_RANKINGS_FORMULA_LINK,
  [TEAM_RANKING_TYPES.RECRUITING]: PATHS.RECRUITING_TEAM_RANKINGS_FORMULA_LINK,
  [TEAM_RANKING_TYPES.TRANSFER]: PATHS.TRANSFER_TEAM_RANKINGS_FORMULA_LINK
};

export const TEAM_RECRUITING_TAB: TeamRankingTab = {
  icon: <Star fill={commonStyles["recruit"]} />,
  key: TEAM_RANKING_TYPES.RECRUITING,
  title: TEAM_RANKING_TYPES.RECRUITING.toUpperCase()
};

export const TEAM_RANKING_TABS: TeamRankingTab[] = [
  {
    icon: <TwoStars />,
    key: TEAM_RANKING_TYPES.COMPREHENSIVE,
    title: TEAM_RANKING_TYPES.COMPREHENSIVE.toUpperCase()
  },
  {
    icon: <Star fill={commonStyles["recruit"]} />,
    key: TEAM_RANKING_TYPES.RECRUITING,
    title: TEAM_RANKING_TYPES.RECRUITING.toUpperCase()
  },
  {
    icon: <Star fill={commonStyles["transfer"]} />,
    key: TEAM_RANKING_TYPES.TRANSFER,
    title: TEAM_RANKING_TYPES.TRANSFER.toUpperCase()
  }
];
