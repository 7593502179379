import Select from "antd/lib/select";
import classNames from "classnames";
import { ReactElement, useState } from "react";
import Chevron from "rivals/components/shared/Icons/Chevron";
import { ROTATE_90 } from "rivals/shared/constants";
import { isEmptyValue } from "rivals/shared/utils/isEmptyValue";
import { setData } from "rivals/shared/utils/qa";
import styleVars from "styles/exports.module.scss";
import styles from "./Select.module.scss";
import {
  OptionValue,
  Props,
  SelectElementProps,
  SelectGroupElementProps,
  SelectVariant
} from "./types";

const variantStyleMap = {
  [SelectVariant.BLUE_WITH_BOTTOM_BORDER]: {
    dropdownBaseClass: undefined,
    iconColor: undefined,
    menuClass: styles.selectWithBlueBottomBorderAndValue
  },
  [SelectVariant.BORDER_NONE]: {
    dropdownBaseClass: undefined,
    iconColor: undefined,
    menuClass: styles.selectWithoutBorders
  },
  [SelectVariant.BORDER_ROUND]: {
    dropdownBaseClass: undefined,
    iconColor: undefined,
    menuClass: styles.selectWithRoundedBorders
  },
  [SelectVariant.DARK]: {
    dropdownBaseClass: styles.selectDarkPopUp,
    iconColor: styleVars.abyss,
    menuClass: styles.selectDark
  },
  [SelectVariant.DEFAULT]: {
    dropdownBaseClass: undefined,
    iconColor: undefined,
    menuClass: undefined
  }
};

const DEFAULT_LIST_HEIGHT = 256;

function SelectWrapper<T extends OptionValue>({
  allowClear = false,
  ariaLabel,
  className,
  feedback,
  data = "",
  defaultValue,
  disabled = false,
  dropdownClassName,
  id,
  labelRender,
  listHeight = DEFAULT_LIST_HEIGHT,
  onChange,
  onClear = (): void => {},
  onOpen = (): void => {},
  options,
  groupOptions,
  placeholder,
  wrapperClassName = "",
  showSearch = false,
  value,
  variant = SelectVariant.DEFAULT,
  width,
  optionFilterProp = "children",
  filterOption = (input, option): boolean =>
    (option?.children?.toString() ?? "")
      .toLowerCase()
      .includes(input.toLowerCase()),
  filterSort = (optionA, optionB): number =>
    (optionA?.children?.toString() ?? "")
      .toLowerCase()
      .localeCompare((optionB?.children?.toString() ?? "").toLowerCase())
}: Props<T>): ReactElement {
  const [selectionOpen, setSelectionOpen] = useState<boolean>(false);
  const ROTATION_ANGLE = selectionOpen ? ROTATE_90 : -ROTATE_90;
  const { dropdownBaseClass, iconColor, menuClass } = variantStyleMap[variant];

  const selectElement = ({
    disabled,
    name,
    value
  }: SelectElementProps<T>): ReactElement => {
    // Index cannot be used as a unique key when filtering bc the index changes
    // Use value instead
    return (
      <Select.Option
        className={classNames({
          [styles.option]: true,
          [styles.readOnly]: disabled
        })}
        disabled={disabled}
        key={value}
        value={value}
        {...setData(data, name?.toString())}
      >
        {name}
      </Select.Option>
    );
  };

  const selectGroupElement = ({
    idx,
    name,
    children
  }: SelectGroupElementProps<T>): ReactElement => {
    return (
      <Select.OptGroup
        key={children[idx]?.value || idx}
        label={name}
        {...setData(data, name?.toString())}
      >
        {children.map(child =>
          selectElement({
            ...child
          })
        )}
      </Select.OptGroup>
    );
  };

  return (
    <div className={styles.selectWrapper}>
      <div
        className={classNames(menuClass, wrapperClassName, {
          [styles.isOpen]: selectionOpen,
          [styles.hasModifiedValue]: !isEmptyValue(value)
        })}
        style={{ width: width }}
      >
        <Select<T>
          allowClear={allowClear}
          aria-label={ariaLabel}
          className={classNames(className, {
            [styles.errorStatus]: !!feedback
          })}
          defaultValue={defaultValue}
          disabled={disabled}
          filterOption={filterOption}
          filterSort={filterSort}
          id={id}
          labelRender={labelRender}
          listHeight={listHeight}
          onChange={onChange}
          onClear={onClear}
          onDropdownVisibleChange={(open: boolean): void => {
            setSelectionOpen(open);
            onOpen(open);
          }}
          optionFilterProp={optionFilterProp}
          placeholder={placeholder}
          popupClassName={classNames(dropdownClassName, dropdownBaseClass)}
          showSearch={showSearch}
          size="large"
          suffixIcon={
            <Chevron animateRotation fill={iconColor} rotate={ROTATION_ANGLE} />
          }
          value={value}
          {...setData(data, "select")}
        >
          {options?.map(item => selectElement(item))}
          {groupOptions?.map((item, idx) =>
            selectGroupElement({
              idx,
              ...item
            })
          )}
        </Select>
      </div>
    </div>
  );
}

export default SelectWrapper;
