import CollegeLogo from "rivals/components/shared/CollegeLogo";
import Link from "rivals/components/shared/Link";
import styles from "./TeamSelector.module.scss";
import type { TeamSelectionItem } from "./TeamSelector.types";

const TeamOption = (props: TeamSelectionItem): React.JSX.Element => {
  const { teamLogo, teamName, teamUrl } = props;

  return (
    <Link className={styles.teamOptionLink} href={teamUrl}>
      <CollegeLogo
        collegeLogo={teamLogo}
        collegeName={teamName}
        logoSize={24}
      />
      <span>{teamName}</span>
    </Link>
  );
};

export default TeamOption;
