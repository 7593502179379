// Benji Listeners
export const BENJI_READY_LISTENER = "benji:ready";

// Ad region constants
export const REGION = "index";

// Ad path constants
const AD_PATH_BASE = process.env.NEXT_PUBLIC_BENJI_AD_PATH;
const RIVALS_PATH_BASE = "us_ysports_rivals_";

// Mobile ad paths
export const MOBILE_TOP_CENTER_PATH = `${AD_PATH_BASE}mw/${RIVALS_PATH_BASE}mw_top_center`;
export const MOBILE_MID_CENTER_PATH = `${AD_PATH_BASE}mw/${RIVALS_PATH_BASE}mw_mid_center`;

// Desktop ad paths
export const DESKTOP_TOP_RIGHT_PATH = `${AD_PATH_BASE}dt/${RIVALS_PATH_BASE}dt_top_right`;
export const DESKTOP_MID_CENTER_PATH = `${AD_PATH_BASE}dt/${RIVALS_PATH_BASE}dt_mid_center`;
export const DESKTOP_MID_RIGHT_PATH = `${AD_PATH_BASE}dt/${RIVALS_PATH_BASE}dt_mid_right`;
export const DESKTOP_TOP_LEFT_PATH = `${AD_PATH_BASE}dt/${RIVALS_PATH_BASE}dt_top_left`;
export const DESKTOP_TOP_CENTER_PATH = `${AD_PATH_BASE}dt/${RIVALS_PATH_BASE}dt_top_center`;

// Ad loc constants
export const TOP_CENTER = "top_center";
export const TOP_LEFT = "top_left";
export const MID_LEFT = "mid_left";
export const MID_CENTER = "mid_center";
export const TOP_RIGHT = "top_right";
export const MID_RIGHT = "mid_right";

// Ad dimensions

// LREC
const LREC_AD_WIDTH = 300;
const LREC_AD_HEIGHT_SM = 250;
const LREC_AD_HEIGHT_LG = 600;

export const LREC_AD_DIMS_SM = [LREC_AD_WIDTH, LREC_AD_HEIGHT_SM];
export const LREC_AD_DIMS_LG = [LREC_AD_WIDTH, LREC_AD_HEIGHT_LG];
export const LREC_AD_DIMS = [LREC_AD_DIMS_SM, LREC_AD_DIMS_LG];

// LDRB
/* eslint-disable no-magic-numbers */
export const LDRB_AD_DIMS = [728, 90];

// WFPAD
export const WFPAD_AD_DIMS = [320, 50];

// MAST
const MAST_AD_DIMS = [970, 250];

// E2E
export const E2E_SIZE = [3, 1];
export const E2E_DIM_0 = [16, 9];
export const E2E_DIM_1331 = [16, 3];
export const E2E_DIM_481 = [40, 9];
export const E2E_AD_DIMS = [LDRB_AD_DIMS, MAST_AD_DIMS, E2E_SIZE];
export const E2E_MOBILE_AD_DIMS = [WFPAD_AD_DIMS, E2E_SIZE];
/* eslint-enable no-magic-numbers */
export enum LDRB_E2E_BP {
  BP_0 = "0,480",
  BP_1331 = "1331,Infinity",
  BP_481 = "481,1330"
}
