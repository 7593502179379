"use client";

import Cookies from "js-cookie";
import Script from "next/script";
import { useEffect, useState } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useGetRenderAdsForUser } from "rivals/shared/app/client";
import {
  ATS_TYPES,
  DEBUG_ADS_COOKIE,
  ROOT_SITE_IDENTIFIER
} from "rivals/shared/constants";
import { AdPageTypes, Props } from "./types";

const BENJI_SCRIPT_SRC = `${process.env.NEXT_PUBLIC_BENJI_SRC_PATH}${process.env.NEXT_PUBLIC_BENJI_VERSION}.js`;

const getDeviceType = (): SupportedDevice => {
  if (isMobileOnly) {
    return "smartphone";
  } else if (isTablet) {
    return "tablet";
  } else {
    return "desktop";
  }
};

export type BenjiAdSlot =
  | "boxE2E"
  | "boxLDRB"
  | "boxLDRB2"
  | "boxLDRB3"
  | "boxLREC"
  | "boxLREC2"
  | "boxLREC3"
  | "boxLREC4";

const makeBenjiConfig = (
  pageType: AdPageTypes,
  spaceId: number | string,
  usercountry: string,
  allowOnlyLimitedAds: boolean,
  allowOnlyNonPersonalizedAds: boolean,
  pubid: string,
  hashtag?: string
): BenjiConfig => {
  return {
    // Requires Yahoo VPN to view
    // https://git.ouryahoo.com/monetization/benji#interface
    // Page level custom instrumentation
    feature: {
      disableAssertiveYield:
        process.env.NEXT_PUBLIC_BENJI_ENABLE_ASSERTIVE_YIELD !== "true",
      enableYahooPrebid:
        process.env.NEXT_PUBLIC_BENJI_ENABLE_YAHOO_PREBID === "true"
    },
    i13n: {
      device: getDeviceType(),
      ...(hashtag && { hashtag: hashtag.split(";") }),
      lang: "en-US",
      pt: pageType || AdPageTypes.CONTENT,
      pubid: pubid,
      region: ATS_TYPES.US,
      site: ROOT_SITE_IDENTIFIER,
      spaceid: spaceId.toString(),
      usercountry: usercountry
    },
    // We could still put positions here if we want, but for now they are all dynamically rendered
    positions: {},
    setting: {
      consent: {
        allowOnlyLimitedAds,
        allowOnlyNonPersonalizedAds
      },
      // Global setting, not sure if we will always want this
      lazyLoad: process.env.NEXT_PUBLIC_BENJI_ENABLE_LAZY_LOADING === "true",
      refresh: {
        duration: parseInt(
          process.env.NEXT_PUBLIC_BENJI_REFRESH_DURATION_SECONDS || "20"
        ),
        limit: parseInt(process.env.NEXT_PUBLIC_BENJI_REFRESH_LIMIT || "100"),
        requireUserAction:
          process.env.NEXT_PUBLIC_BENJI_REQUIRE_USER_ACTION === "true",
        sameSizeRefresh:
          process.env.NEXT_PUBLIC_BENJI_SAME_SIZE_REFRESH === "true",
        tabFocus: {
          outOfFocusDuration: parseInt(
            process.env.NEXT_PUBLIC_BENJI_TAB_FOCUS_REFRESH_DURATION_SECONDS ||
              "3"
          )
        }
      },
      // Because we are manually rendering the ads, there is nothing to render on start
      renderOnStart: false
    }
  };
};

/*
 * Client-only component to mount Benji script
 * Initializes Benji with config once when script is ready and Benji config is resolved.
 * Renders on client only to avoid SSR issues with ads.
 *
 * Important: This component should only be included once per page. Multiple inclusions will result
 *   in multiple benji scripts appended to page and multiple benji.start invocations. The best place
 *   to include is probably the page level component in the page or app router.
 */
function Benji({
  pageType = AdPageTypes.CONTENT,
  hashtag,
  ...props
}: Props): React.JSX.Element {
  const debugAds = Cookies.get(DEBUG_ADS_COOKIE) === "true";
  const [scriptReady, setScriptReady] = useState<boolean>(false);

  const data = useGetRenderAdsForUser();

  useEffect(() => {
    if (debugAds) {
      // eslint-disable-next-line no-console
      console.log("Ads Debug ==> Benji script ready:", scriptReady);
      // eslint-disable-next-line no-console
      console.log("Ads Debug ==> Consent data:", data.consentData);
    }

    if (
      scriptReady &&
      data.consentData?.thirdPartyEmbedConsent &&
      data.site &&
      data?.adSettings?.spaceId &&
      !data.adSettings?.suppressAds &&
      !data.user?.user?.adLite
    ) {
      const benjiConfig = makeBenjiConfig(
        pageType,
        data.adSettings.spaceId,
        data.consentData.usercountry,
        data.consentData.allowOnlyLimitedAds,
        data.consentData.allowOnlyNonPersonalizedAds,
        data.site.shortName,
        hashtag
      );

      if (debugAds) {
        // eslint-disable-next-line no-console
        console.log("Ads Debug ==> Benji config resolved to:", benjiConfig);
      }

      if (window.benji) {
        if (debugAds) {
          // eslint-disable-next-line no-console
          console.log("Ads Debug ==> Benji starting.");
        }
        window.benji.start && window.benji.start(benjiConfig);
      } else {
        window.benji = { config: benjiConfig };
      }
    } else {
      if (debugAds) {
        // eslint-disable-next-line no-console
        console.log("Ads Debug ==> Benji load blocked");
      }
    }
  }, [scriptReady, data, debugAds, hashtag, pageType]);

  const onReady = (): void => {
    setScriptReady(true);
  };

  const onError = (e: Error): void => {
    if (debugAds) {
      // eslint-disable-next-line no-console
      console.error("Ads Debug ==> Benji script error:", e);
    }

    props.onError && props.onError(e);
  };

  return (
    <Script
      id="benji-start"
      onError={onError}
      onReady={onReady}
      src={BENJI_SCRIPT_SRC}
      // Rendering clent side to ensure that CCPA data is available
      // https://nextjs.org/docs/pages/api-reference/components/script
      // https://nextjs.org/docs/app/api-reference/components/script
      strategy="afterInteractive"
    />
  );
}

export default Benji;
