"use client";

import Script from "next/script";
import { useEffect } from "react";
import useGetCCPAFooter from "rivals/services/getCCPAFooter/useGetCCPAFooter";

function setConfig({
  authors,
  domain,
  sections,
  title,
  uid
}: {
  authors: string;
  domain: string;
  sections: string;
  title: string;
  uid: string;
}): undefined {
  const sfAsyncConfig = (window._sf_async_config =
    window._sf_async_config || {});
  sfAsyncConfig.uid = uid;
  sfAsyncConfig.domain = domain;
  sfAsyncConfig.useCanonical = true;
  sfAsyncConfig.useCanonicalDomain = true;
  sfAsyncConfig.authors = authors;
  sfAsyncConfig.sections = sections;
  sfAsyncConfig.title = title;
}

export function Chartbeat(params: {
  authors: string;
  domain: string;
  sections: string;
  title: string;
}): JSX.Element | null {
  const chartbeatApi = process.env.NEXT_PUBLIC_CHARTBEAT_ACCOUNT_ID;

  useEffect(() => {
    if (!chartbeatApi) {
      return;
    }
    setConfig({ uid: chartbeatApi, ...params });
  }, [chartbeatApi, params]);

  const { data } = useGetCCPAFooter();

  if (!data?.thirdPartyEmbedConsent || !chartbeatApi) {
    return null;
  }

  return (
    <Script
      async={true}
      src={
        process.env.NEXT_PUBLIC_CHARTBEAT_SRC_URL ||
        "//static.chartbeat.com/js/chartbeat.js"
      }
      type="text/javascript"
    />
  );
}
