import camelCase from "lodash/camelCase";
import ExternalContent from "rivals/components/shared/Icons/ExternalContent";
import ForumsContent from "rivals/components/shared/Icons/ForumsContent";
import VideoContent from "rivals/components/shared/Icons/VideoContent";
import styles from "rivals/styles/exports.module.scss";
import type { Props } from "./types";

const iconSize = "11px";

/**
 * @param contentType the news article content type which can be video, article, link or forumThread
 * This param is usually returned from the API prefixed with `Content::`.
 * @returns content type stripped from its prefix `Content::` and camelCased or undefined so if
 * contentType was returned from the API as `ContentType::ForumThread` this will translate it
 * to just `forumThread`, and if it was returned from the API as `forum_thread` this function
 * will translate it to forumThread.
 */
export const setContentType = (contentType?: string): string | undefined => {
  if (contentType) {
    const regexp = /^content::/gi;
    const strippedContentType = regexp.test(contentType)
      ? contentType.replace(regexp, "")
      : contentType;
    return camelCase(strippedContentType);
  }
};

const ContentType = ({
  contentType,
  darkMode
}: Props): React.JSX.Element | null => {
  const fillColor = darkMode ? styles["bone"] : styles["abyss"];
  const strippedContentType = setContentType(contentType);

  switch (strippedContentType) {
    case "forumThread":
      return (
        <ForumsContent fill={fillColor} height={iconSize} width={iconSize} />
      );
    case "link":
      return (
        <ExternalContent fill={fillColor} height={iconSize} width={iconSize} />
      );
    case "video":
      return (
        <VideoContent fill={fillColor} height={iconSize} width={iconSize} />
      );
    default:
      return null;
  }
};

export default ContentType;
