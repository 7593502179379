"use client";

import classNames from "classnames";
import React, { useEffect, useState } from "react";
import CollegeLogo from "rivals/components/shared/CollegeLogo";
import ArrowIcon from "rivals/components/shared/Icons/ArrowIcon";
import { variantFillColor } from "rivals/components/shared/Icons/iconUtils";
import {
  formatGameStartingDate,
  isGameCurrent,
  isGameFinal,
  isGameUpcoming
} from "rivals/components/shared/Widgets/utils";
import { GameScore, GameTeam } from "rivals/shared/interfaces/GameScore";
import { setData } from "rivals/shared/utils/qa";
import styles from "./GameCard.module.scss";
import { Props } from "./types";

function ScoreInset({
  team,
  isAwayTeam,
  gameData,
  isDarkMode
}: {
  gameData: GameScore;
  isAwayTeam: boolean;
  isDarkMode: boolean;
  team: GameTeam;
}): React.JSX.Element {
  const showScores = !isGameUpcoming(gameData.status);
  const winLossRecord = `${team.overallRecord.wins} - ${team.overallRecord.losses}`;
  const scoreInset = isAwayTeam ? gameData.awayScore : gameData.homeScore;

  const displayText = showScores ? scoreInset : winLossRecord;

  const winner =
    isGameFinal(gameData.status) && gameData.winningTeamId == team.teamId;

  return (
    <div
      className={classNames(styles.statLine, {
        [styles.scoreLine]: showScores
      })}
    >
      {displayText}
      {winner && (
        <div
          className={classNames(styles.winner, {
            [styles.awayWinner]: isAwayTeam
          })}
        >
          <ArrowIcon
            fill={variantFillColor(isDarkMode)}
            height={"10"}
            width={"10"}
          />
        </div>
      )}
    </div>
  );
}

function TeamBlock({
  team,
  isAwayTeam,
  isDarkMode,
  gameData
}: {
  gameData: GameScore;
  isAwayTeam: boolean;
  isDarkMode: boolean;
  team: GameTeam;
}): React.JSX.Element {
  return (
    <div className={styles.teamBlock}>
      <div
        className={classNames(styles.primary, {
          [styles.awayTeam]: isAwayTeam
        })}
      >
        <CollegeLogo
          collegeLogo={team.teamLogoWhite.url}
          collegeName={team.displayName}
          logoSize={40}
        />
        <ScoreInset
          gameData={gameData}
          isAwayTeam={isAwayTeam}
          isDarkMode={isDarkMode}
          team={team}
        />
      </div>
      <div className={styles.secondary}>{team.displayName}</div>
    </div>
  );
}

function GameCard({ darkMode = false, gameData }: Props): React.JSX.Element {
  const [headerText, setHeaderText] = useState<string>("");
  const { homeTeam, awayTeam } = gameData;
  const philter = "game-card";

  useEffect(() => {
    const text = isGameCurrent(gameData.status)
      ? `LIVE - ${gameData.currentPeriod?.shortDisplayName} - ${gameData.timeLeft}`
      : formatGameStartingDate(gameData.startTime, gameData.status);

    setHeaderText(text);
  }, [gameData]);

  return (
    <div
      className={classNames(styles.gameCard, {
        [styles.activeGame]: isGameCurrent(gameData.status),
        [styles.darkMode]: darkMode
      })}
      {...setData(philter, gameData.gameId)}
    >
      <div
        className={styles.colorHeader}
        style={{
          backgroundColor: `#${awayTeam.primaryColor}`
        }}
      >
        <div
          className={styles.homeTeamColorHeader}
          style={{
            backgroundColor: `#${homeTeam.primaryColor}`
          }}
        />
      </div>
      <div className={styles.statusHeader}>
        {isGameCurrent(gameData.status) && (
          <div className={styles.liveIndicator} />
        )}
        {headerText}
      </div>
      <div className={styles.gameStatistics}>
        <div className={styles.homeTeam}>
          <TeamBlock
            gameData={gameData}
            isAwayTeam={false}
            isDarkMode={darkMode}
            team={homeTeam}
          />
        </div>
        <div className={styles.awayTeam}>
          <TeamBlock
            gameData={gameData}
            isAwayTeam={true}
            isDarkMode={darkMode}
            team={awayTeam}
          />
        </div>
      </div>
      {isGameUpcoming(gameData.status) &&
        gameData.gameOddsSummary &&
        gameData.gameOddsSummary.pregameOddsDisplay && (
          <div className={styles.bettingOdds}>
            {gameData.gameOddsSummary.pregameOddsDisplay}
          </div>
        )}
    </div>
  );
}

export default GameCard;
