"use client";
import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ButtonWrapper from "rivals/components/shared/Button";
import ExternalLinkIcon from "rivals/components/shared/Icons/ExternalLinkIcon";
import EmptyStateMessage from "rivals/components/shared/Widgets/EmptyStateMessage";
import WidgetHeader from "rivals/components/shared/Widgets/WidgetHeader";
import WidgetLayout from "rivals/components/shared/Widgets/WidgetLayout";
import widgetStyles from "rivals/components/shared/Widgets/Widgets.module.scss";
import { getWidgetDataLengthLimit } from "rivals/components/shared/Widgets/utils";
import { buildRefLink } from "rivals/services/referral";
import {
  DATETIME_MMM_D_YYYY_H_MM_A,
  PATHS,
  SEE_MORE,
  SEE_MORE_ON_RIVALS
} from "rivals/shared/constants";
import { WIDGET_SIZE } from "rivals/shared/interfaces/Widgets";
import { setData } from "rivals/shared/utils/qa";
import CommitmentRow from "./CommitmentRow";
import styles from "./Commitments.module.scss";
import strings from "./strings";
import { Props } from "./types";

function Commitments({
  buttonFullWidth = false,
  darkMode = false,
  externalLink = false,
  primaryColor,
  siteFriendlyName,
  prospects,
  removeBorder = false,
  showTopBar = true,
  size = WIDGET_SIZE.FULL_SIZE,
  sponsor,
  sport,
  useYearOverride,
  width,
  year,
  reflink
}: Props): React.JSX.Element {
  if (useYearOverride) {
    year = "2025"; // TODO: un-hardcode year: https://griosf.atlassian.net/browse/RVLS-9307
  }

  const philter = "commitments-widget";
  const [lastUpdated, setLastUpdated] = useState("");
  const filteredCommitments = getWidgetDataLengthLimit(prospects, size);
  const buttonUrl = buildRefLink(
    PATHS.COMMITMENTS.replace("[year]", year).replace("[sport]", sport),
    reflink
  );

  useEffect(() => {
    setLastUpdated(moment().format(DATETIME_MMM_D_YYYY_H_MM_A));
  }, []);

  const title = (
    <WidgetHeader
      eyebrowText={siteFriendlyName}
      subheaderText={`Updated: ${lastUpdated}`}
      title={strings.commitmentList}
      year={year}
    />
  );

  return (
    <WidgetLayout
      button={
        <ButtonWrapper
          buttonText={externalLink ? SEE_MORE_ON_RIVALS : SEE_MORE}
          className={classNames(widgetStyles.externalIcon, {
            [widgetStyles.darkMode]: darkMode,
            [widgetStyles.smallButton]: buttonFullWidth
          })}
          dark={darkMode}
          fullWidth
          icon={externalLink ? <ExternalLinkIcon fill="none" /> : undefined}
          inverted
          shouldAddNewTab={externalLink}
          url={buttonUrl}
        />
      }
      buttonFullWidth={buttonFullWidth}
      darkMode={darkMode}
      removeBorder={removeBorder}
      sponsor={sponsor}
      title={title}
      width={width}
      {...(showTopBar ? { topBorderColor: `#${primaryColor}` } : {})}
    >
      <div {...setData(philter)}>
        <div className={styles.rowTitles} {...setData(philter, "titles")}>
          <div className={styles.athleteColumn}>{strings.athlete}</div>
          <div>{strings.position}</div>
          <div>{strings.stars}</div>
        </div>
        {filteredCommitments.length > 0 && (
          <ul
            className={styles.commitmentList}
            {...setData(philter, "prospect-list")}
          >
            {filteredCommitments.map((prospect, index) => {
              return (
                <li
                  className={classNames(
                    styles.prospectRow,
                    widgetStyles.dataRow,
                    {
                      [widgetStyles.darkModeRow]: darkMode
                    }
                  )}
                  key={index}
                  {...setData(philter, `row-${index}`)}
                >
                  <CommitmentRow
                    darkMode={darkMode}
                    externalLink={externalLink}
                    philter={philter}
                    prospect={prospect}
                    reflink={reflink}
                  />
                </li>
              );
            })}
          </ul>
        )}
        {filteredCommitments.length === 0 && (
          <EmptyStateMessage
            darkMode={darkMode}
            message={strings.emptyStateMessage}
            philter={philter}
          />
        )}
      </div>
    </WidgetLayout>
  );
}

export default Commitments;
