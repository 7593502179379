import { ReactElement } from "react";
import styles from "rivals/components/shared/Icons/Icons.module.scss";
import { IconProps } from "./iconPropsTypes";

const ExternalLinkIcon = ({
  fill = styles.abyss,
  height = "24",
  width = "24"
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    role="graphics-document"
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1667 6.5V8.33333H5.58333L5.58333 18.4167H15.6667V13.8333H17.5V19.3333C17.5 19.5764 17.4034 19.8096 17.2315 19.9815C17.0596 20.1534 16.8264 20.25 16.5833 20.25H4.66667C4.42355 20.25 4.19039 20.1534 4.01849 19.9815C3.84658 19.8096 3.75 19.5764 3.75 19.3333L3.75 7.41667C3.75 7.17355 3.84658 6.94039 4.01849 6.76849C4.19039 6.59658 4.42355 6.5 4.66667 6.5L10.1667 6.5ZM20.25 3.75V11.0833H18.4167V6.87858L11.2731 14.0231L9.97692 12.7269L17.1196 5.58333L12.9167 5.58333V3.75L20.25 3.75Z"
      fill={fill}
    />
    <title>External Link</title>
  </svg>
);

export default ExternalLinkIcon;
