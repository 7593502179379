"use client";

import classNames from "classnames";
import React from "react";
import ButtonWrapper from "rivals/components/shared/Button";
import styles from "rivals/components/shared/Community/Community.module.scss";
import { ForumsFeedCard } from "rivals/components/shared/Community/ForumsFeedCard";
import { Props } from "rivals/components/shared/Community/ForumsFeedCards/types";
import strings from "rivals/components/shared/Community/strings";
import { MAX_MOBILE_CARD_NUM } from "rivals/components/shared/Community/utils";
import MediaWrapper from "rivals/components/shared/Media";
import { MEDIA_BREAKPOINTS } from "rivals/media";

const { community } = strings;

export default function ForumsFeedCards({
  isDarkMode,
  feeds,
  siteColor,
  siteName,
  siteSettingForumsUrl
}: Props): JSX.Element {
  const numberDesktopCards = feeds.length + 1;

  const mobileFeedsCards = feeds
    .slice(0, MAX_MOBILE_CARD_NUM)
    .map(feed => (
      <ForumsFeedCard
        feed={feed}
        isDarkMode={isDarkMode}
        isWidget={false}
        key={feed.title}
      />
    ));

  const desktopFeedsCards = feeds
    .slice(0, numberDesktopCards)
    .map(feed => (
      <ForumsFeedCard
        feed={feed}
        isDarkMode={isDarkMode}
        isWidget={false}
        key={feed.title}
      />
    ));

  return (
    <>
      <div className={styles.title}>
        {
          <div
            className={styles.siteTitle}
            style={{ backgroundColor: `#${siteColor}` }}
          >
            {siteName} {community}
          </div>
        }
      </div>
      <MediaWrapper lessThan={MEDIA_BREAKPOINTS.MD}>
        <div className={classNames(styles.cardsContainer)}>
          {mobileFeedsCards}
        </div>
      </MediaWrapper>
      <MediaWrapper greaterThanOrEqual={MEDIA_BREAKPOINTS.MD}>
        <div className={classNames(styles.cardsContainer)}>
          {desktopFeedsCards}
        </div>
      </MediaWrapper>
      <div className={styles.actionContainer}>
        <div className={styles.visitContainer}>
          <ButtonWrapper
            buttonText={strings.siteCardsButtonText}
            className={styles.ctaButton}
            dark={isDarkMode}
            inverted
            url={siteSettingForumsUrl}
          />
        </div>
      </div>
    </>
  );
}
