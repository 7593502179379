import styles from "rivals/components/shared/Icons/Icons.module.scss";
import type { IconProps } from "./iconPropsTypes";

const VideoContent = ({
  fill = styles.abyss,
  height = "16px",
  width = "16px"
}: IconProps): React.JSX.Element => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Video content</title>
      <path
        clipRule="evenodd"
        d="M24 0H0V24H24V0ZM2 22V2H22V22H2ZM8 17.1962L17 12L8 6.80385L8 17.1962Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default VideoContent;
