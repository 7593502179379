import { ReactElement } from "react";
import styles from "rivals/components/shared/Icons/Icons.module.scss";
import type { IconProps } from "./iconPropsTypes";

const TwoStars = ({
  fill,
  height = "19",
  width = "19"
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1030_93414)">
      <path
        clipRule="evenodd"
        d="M8.5 11.75L4.6794 13.7586L5.40907 9.50431L2.31813 6.49139L6.5897 5.87069L8.5 2L10.4103 5.87069L14.6819 6.49139L11.5909 9.50431L12.3206 13.7586L8.5 11.75Z"
        fill={fill || styles["recruit"]}
        fillRule="evenodd"
      />
    </g>
    <g clipPath="url(#clip1_1030_93414)">
      <path
        clipRule="evenodd"
        d="M15.5 19.75L11.6794 21.7586L12.4091 17.5043L9.31813 14.4914L13.5897 13.8707L15.5 10L17.4103 13.8707L21.6819 14.4914L18.5909 17.5043L19.3206 21.7586L15.5 19.75Z"
        fill={fill || styles["transfer"]}
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_1030_93414">
        <rect
          fill="white"
          height={height}
          transform="translate(2 2)"
          width={width}
        />
      </clipPath>
      <clipPath id="clip1_1030_93414">
        <rect
          fill="white"
          height={height}
          transform="translate(9 10)"
          width={width}
        />
      </clipPath>
    </defs>
  </svg>
);

export default TwoStars;
