import { FunctionComponent, useEffect, useState } from "react";
import { formatTimestamp, updateLocale } from "rivals/shared/utils/time";

type Props = { className: string; liveAt: string; short?: boolean };

const Timestamp: FunctionComponent<Props> = ({
  className,
  liveAt,
  short = false
}) => {
  const [timestamp, setTimestamp] = useState("");

  useEffect(() => {
    const getTimestamp = async (): Promise<void> => {
      const time = await formatTimestamp(liveAt, short);
      setTimestamp(time);
    };

    updateLocale().then(() => {
      getTimestamp();
    });
  }, [liveAt, short]);

  return (
    <div className={className} title={timestamp}>
      {timestamp}
    </div>
  );
};

export default Timestamp;
