"use client";

import classNames from "classnames";
import startCase from "lodash/startCase";
import { useState } from "react";
import Button from "rivals/components/shared/Button";
import ContentCard from "rivals/components/shared/ContentCard";
import { CONTENT_CARD_VARIANTS } from "rivals/components/shared/ContentCard/types";
import Chevron from "rivals/components/shared/Icons/Chevron";
import { replaceMultiple } from "rivals/shared/utils/strings";
import styles from "./ContentModule.module.scss";
import strings from "./strings";
import type { Props } from "./types";
import { DEFAULT_ITEMS_TO_SHOW, MAX_ITEMS_TO_SHOW } from "./utils";

/**
 * "mol" is an abbreviation for "more or less"
 */

const ContentModule = ({
  contentItems,
  site,
  title
}: Props): React.JSX.Element | null => {
  // replaces white spaces in title with dashes
  const philter = title.toLowerCase().replace(/\s/gi, "-");
  const [itemsToShow, setItemsToShow] = useState(DEFAULT_ITEMS_TO_SHOW);
  const canShowMore = itemsToShow === DEFAULT_ITEMS_TO_SHOW;

  if (!contentItems.length) {
    return null;
  }

  const toggleVisibleItems = (): void => {
    if (canShowMore) {
      setItemsToShow(MAX_ITEMS_TO_SHOW);
      return;
    }
    setItemsToShow(DEFAULT_ITEMS_TO_SHOW);
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.contentModuleTitle}>{title}</h2>
      {contentItems.slice(0, itemsToShow).map((item, index) => (
        <div className={styles.cardWrapper} key={index}>
          <ContentCard
            {...item}
            className={styles.contentCard}
            philter={philter}
            site={site}
            variant={
              index === 0
                ? CONTENT_CARD_VARIANTS.HIGHLIGHTED
                : CONTENT_CARD_VARIANTS.COMMON
            }
          />
        </div>
      ))}
      {/* when there are more articles that can be shown */}
      {contentItems.length > DEFAULT_ITEMS_TO_SHOW && (
        <Button
          backgroundColor="transparent"
          borderColor="transparent"
          buttonText={replaceMultiple(strings.seeButton, {
            /* eslint-disable @typescript-eslint/naming-convention */
            "[module]": startCase(title), // capitalizes the first letter of each word
            "[mol]": canShowMore ? strings.more : strings.less
            /* eslint-enable @typescript-eslint/naming-convention */
          })}
          className={classNames(styles.seeMolButton, {
            [styles.seeMore]: canShowMore
          })}
          data={`${philter}-see-mol`}
          fullWidth
          icon={<Chevron height="16px" width="16px" />}
          onClick={toggleVisibleItems}
        />
      )}
    </div>
  );
};

export default ContentModule;
