import styles from "rivals/components/shared/Icons/Icons.module.scss";
import type { IconProps } from "./iconPropsTypes";

const ForumsContent = ({
  fill = styles.abyss,
  height = "16px",
  width = "16px"
}: IconProps): React.JSX.Element => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Forums content</title>
      <g clipPath="url(#clip0_8448_127541)">
        <path
          clipRule="evenodd"
          d="M18.8604 0H0V18.2715L4.44004 14.1738H18.8604V0ZM1.71458 14.4143V1.63425H17.1458V12.5396H3.74597L1.71458 14.4143ZM4.28297 6.53659H13.7132V4.90234H4.28297V6.53659ZM13.7132 9.80221H4.28297V8.16797H13.7132V9.80221ZM24 5.71875H20.1422V7.353H22.2854V20.1242L20.4908 18.4575H6.85415V16.488H5.13957V20.0917H19.7936L24 23.9982V5.71875Z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_8448_127541">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ForumsContent;
