import { useCallback, useEffect, useState } from "react";
import { BENJI_READY_LISTENER } from "rivals/components/shared/Ads/Benji/constants";

export default function useIsBenjiReady(): boolean {
  const [isBenjiReady, setIsBenjiReady] = useState(false);

  const listenerCallback = useCallback(
    function (): void {
      setIsBenjiReady(true);
    },
    [setIsBenjiReady]
  );

  // Check if Benji is ready, pass prop to ad slot
  useEffect(() => {
    if (window.benji && window.benji.isReady) {
      listenerCallback();
    } else {
      window.addEventListener(BENJI_READY_LISTENER, listenerCallback);
    }

    return () => {
      window.removeEventListener(BENJI_READY_LISTENER, listenerCallback);
    };
  }, [listenerCallback]);

  return isBenjiReady;
}
