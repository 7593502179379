// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmptyValue = (value: any): boolean => {
  return (
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" &&
      value !== null &&
      Object.keys(value).length === 0) ||
    !value
  );
};
