"use client";

import classNames from "classnames";
import React, { JSX, useMemo } from "react";
import CommunityWidget from "rivals/components/shared/Community/CommunityWidget";
import ForumsFeedCards from "rivals/components/shared/Community/ForumsFeedCards";
import TopSiteCards from "rivals/components/shared/Community/TopSitesCards";
import {
  getCollegesFromTeamSelectionItems,
  getHighSchoolsFromTeamSelectionItems,
  getTeamSelectionItemsFromSites
} from "rivals/components/shared/TeamSelector/utils";
import TexturedBackground from "rivals/components/shared/TexturedBackground";
import styles from "./Community.module.scss";
import { Props } from "./types";

export default function Community({
  feeds = [],
  isDarkMode = false,
  isNationals = false,
  isWidget = false,
  removeBorder,
  siteColor,
  siteSettingForumsUrl,
  siteName,
  sites = [],
  topForums = []
}: Props): JSX.Element | null {
  const teamSelectionItems = useMemo(
    () => getTeamSelectionItemsFromSites(sites),
    [sites]
  );
  const highSchools = useMemo(
    () => getHighSchoolsFromTeamSelectionItems(teamSelectionItems),
    [teamSelectionItems]
  );
  const colleges = useMemo(
    () => getCollegesFromTeamSelectionItems(teamSelectionItems),
    [teamSelectionItems]
  );

  if (isNationals && !isWidget) {
    return (
      <TexturedBackground
        className={classNames(styles.container, styles.nationals)}
        variant={2}
      >
        <TopSiteCards
          colleges={colleges}
          highSchools={highSchools}
          isDarkMode={isDarkMode}
          isNationals={isNationals}
          isWidget={false}
          topForums={topForums}
        />
      </TexturedBackground>
    );
  }

  if (!feeds.length && !isWidget) {
    return null;
  }

  return (
    <>
      {isWidget ? (
        <CommunityWidget
          feeds={feeds}
          isDarkMode={isDarkMode}
          isNationals={isNationals}
          removeBorder={removeBorder}
        />
      ) : (
        <div className={styles.container}>
          <ForumsFeedCards
            feeds={feeds}
            isDarkMode={isDarkMode}
            siteColor={siteColor}
            siteName={siteName}
            siteSettingForumsUrl={siteSettingForumsUrl}
          />
        </div>
      )}
    </>
  );
}
