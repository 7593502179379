import { PRIMARY_SPORTS } from "rivals/shared/constants";

interface SeasonRecord {
  losses: number;
  wins: number;
}

export enum GameStatuses {
  IN_PROGRESS = "IN_PROGRESS",
  FINAL = "FINAL",
  DELAYED = "DELAYED",
  FORFEITED = "FORFEITED",
  PREGAME = "PREGAME"
}

export const currentGameStatuses = [
  GameStatuses.IN_PROGRESS,
  GameStatuses.DELAYED
];
export const nextGameStatuses = [GameStatuses.PREGAME];
export const previousGameStatuses = [
  GameStatuses.FINAL,
  GameStatuses.FORFEITED
];

export interface GameScore {
  awayScore?: number | null;
  awayTeam: GameTeam;
  currentPeriod?: {
    displayName: string;
    overtime: boolean;
    period: number;
    shortDisplayName: string;
  };
  gameId: string;
  gameOddsSummary: { pregameOddsDisplay: string };
  homeScore?: number | null;
  homeTeam: GameTeam;
  startTime: string;
  status: GameStatuses;
  statusDisplayName: string;
  timeLeft?: string;
  winningTeamId?: string | null;
}

export interface GameTeam {
  alias: { url: string };
  conferenceRecord: SeasonRecord;
  currentSeason?: number;
  displayName: string;
  league?: { currentSeason: number };
  overallRecord: SeasonRecord;
  primaryColor: string;
  sport: { name: PRIMARY_SPORTS };
  teamId?: string;
  teamLogoWhite: { url: string };
}

export interface ScoresSchedule extends GameTeam {
  currentGames: GameScore[];
  nextGames: GameScore[];
  previousGames: GameScore[];
}
