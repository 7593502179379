import styles from "rivals/components/shared/Icons/Icons.module.scss";
import type { IconProps } from "./iconPropsTypes";

const ExternalContent = ({
  fill = styles.abyss,
  height = "16px",
  width = "16px"
}: IconProps): React.JSX.Element => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>External content</title>
      <g clipPath="url(#clip0_8448_127549)">
        <path
          clipRule="evenodd"
          d="M24.0025 9.594V0L14.4085 0V2.3985L19.9071 2.3985L10.5625 11.7443L12.2582 13.44L21.604 4.09304V9.594L24.0025 9.594ZM9.96286 2.87891L0 2.87891L0 23.9989L21.12 23.9989L21.12 14.036H19.1086L19.1086 21.9875L2.01143 21.9875L2.01143 4.89034L9.96286 4.89034V2.87891Z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_8448_127549">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExternalContent;
