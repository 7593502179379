import type {
  AdHorizontalPositions,
  AdVertialPositions,
  BenjiLocation
} from "./types";

/**
 * Combines known vertical and horizontal positions to create a valid Benji location string
 *
 * Main purpose if to ensure type safety when creating a location string
 *
 * @param verticalPos: AdVertialPositions
 * @param horizontalPos: AdHorizontalPositions
 * @returns BenjiLocation
 */
export function buildLocationString(
  verticalPos: AdVertialPositions,
  horizontalPos: AdHorizontalPositions
): BenjiLocation {
  return `${verticalPos}_${horizontalPos}`;
}

/**
 * Combines a location string with a count to create a final numbered Benji location string
 *
 * @param location: BenjiLocation
 * @param locationCount: number
 * @returns string: format "mid_center_2"
 */
export function buildLocationStringWithCount(
  location: BenjiLocation,
  locationCount: number
): string {
  return locationCount > 1 ? `${location}_${locationCount}` : location;
}
