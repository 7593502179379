import {
  MID_CENTER,
  MID_LEFT,
  MID_RIGHT,
  TOP_CENTER,
  TOP_LEFT,
  TOP_RIGHT
} from "./constants";

export type AdVertialPositions = "top" | "mid";
export type AdHorizontalPositions = "left" | "center" | "right";
export type BenjiLocation =
  | typeof TOP_LEFT
  | typeof TOP_CENTER
  | typeof MID_CENTER
  | typeof MID_LEFT
  | typeof TOP_RIGHT
  | typeof MID_RIGHT;

export enum AdPageTypes {
  ANCILLARY = "ancillary",
  CONTENT = "content",
  HOME = "home",
  SEARCH = "search"
}

export interface Props {
  hashtag?: string;
  onError?: (error: Error) => void;
  pageType?: AdPageTypes;
}
