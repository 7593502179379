import { ReactElement } from "react";
import { IconProps } from "./iconPropsTypes";

const Basketball = ({
  fill,
  height = "36",
  width = "36"
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 36 36"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3969_122410)">
      <path
        d="M28.3414 7.65785C26.2961 5.6125 23.6902 4.21961 20.8532 3.6553C18.0162 3.09099 15.0756 3.38061 12.4033 4.48755C9.73089 5.59448 7.44678 7.469 5.83976 9.87407C4.23275 12.2791 3.375 15.1067 3.375 17.9993C3.375 20.8918 4.23275 23.7194 5.83976 26.1245C7.44678 28.5296 9.73089 30.4041 12.4033 31.511C15.0756 32.618 18.0162 32.9076 20.8532 32.3433C23.6902 31.779 26.2961 30.3861 28.3414 28.3407C31.0804 25.596 32.6186 21.8768 32.6186 17.9993C32.6186 14.1217 31.0804 10.4026 28.3414 7.65785ZM16.7899 5.68601C16.5548 8.15407 15.5771 10.4929 13.9857 12.394L10.0818 8.49013C11.9845 6.90139 14.3225 5.92405 16.7899 5.68601ZM19.591 17.9993L27.5092 10.0811C29.131 12.026 30.114 14.4236 30.3242 16.9472C27.2538 17.1902 24.3406 18.4051 22.0073 20.4156L19.591 17.9993ZM18 16.4083L15.5837 13.992C17.5942 11.6587 18.8091 8.74549 19.052 5.67508C21.5757 5.88529 23.9733 6.86827 25.9182 8.49013L18 16.4083ZM12.3947 13.985C10.4936 15.5763 8.15479 16.554 5.68673 16.7891C5.92477 14.3218 6.90211 11.9838 8.49085 10.0811L12.3947 13.985ZM13.9927 15.583L16.409 17.9993L8.49085 25.9174C6.86899 23.9725 5.88601 21.575 5.6758 19.0513C8.74615 18.808 11.6592 17.5932 13.9927 15.583ZM18 19.5903L20.4163 22.0066C18.4061 24.3401 17.1912 27.2531 16.948 30.3235C14.4243 30.1133 12.0267 29.1303 10.0818 27.5084L18 19.5903ZM22.0143 23.6045L25.9182 27.5084C24.0155 29.0972 21.6775 30.0745 19.2102 30.3126C19.4453 27.8445 20.4229 25.5057 22.0143 23.6045ZM23.6053 22.0136C25.5064 20.4222 27.8452 19.4445 30.3133 19.2094C30.0752 21.6768 29.0979 24.0148 27.5092 25.9174L23.6053 22.0136Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_3969_122410">
        <rect fill={fill} height="36" width="36" />
      </clipPath>
    </defs>
    <title>basketball</title>
  </svg>
);

export default Basketball;
