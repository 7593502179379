import { ReactElement } from "react";
import styles from "rivals/components/shared/Icons/Icons.module.scss";
import type { IconProps } from "./iconPropsTypes";

const ArrowIcon = ({
  fill = styles.abyss,
  height = "61",
  width = "64"
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 64 61"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5235_89773)">
      <path
        d="M49.2311 30.0658L63.2713 60.6447L-0.00406887 30.0679L64.1895 -0.000588766L49.2311 30.0658Z"
        fill={fill}
      />
    </g>
    <title>Arrow</title>
  </svg>
);

export default ArrowIcon;
