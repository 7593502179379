"use client";

import classNames from "classnames";
import Button from "rivals/components/shared/Button";
import ContentCard from "rivals/components/shared/ContentCard";
import { CONTENT_CARD_VARIANTS } from "rivals/components/shared/ContentCard/types";
import MediaWrapper from "rivals/components/shared/Media";
import { MEDIA_BREAKPOINTS } from "rivals/media";
import { setData } from "rivals/shared/utils/qa";
import styles from "./NewsWidget.module.scss";
import strings from "./strings";
import type { Props } from "./types";
import {
  MAX_ITEMS_TO_SHOW_ON_DESKTOP,
  MAX_ITEMS_TO_SHOW_ON_MOBILE
} from "./utils";

const DATA_PHILTER = "news-widget";

const NewsWidget = ({
  contentItems,
  site,
  sticky = false
}: Props): React.JSX.Element | null => {
  // TODO: https://griosf.atlassian.net/browse/RVLS-9001
  if (!contentItems.length) {
    return null;
  }

  const newsWidget = (maxItemsToShow: number): React.JSX.Element => {
    return (
      <div
        className={classNames(styles.wrapper, { [styles.sticky]: sticky })}
        {...setData(DATA_PHILTER, "wrapper")}
      >
        <h2 className={styles.newsWidgetTitle}>{strings.title}</h2>
        {contentItems.slice(0, maxItemsToShow).map((item, index) => (
          <div className={styles.cardWrapper} key={index}>
            <ContentCard
              {...item}
              className={styles.contentCard}
              philter={DATA_PHILTER}
              site={site}
              variant={CONTENT_CARD_VARIANTS.WIDGET}
            />
          </div>
        ))}
        {contentItems.length >= maxItemsToShow && (
          <Button
            buttonText={strings.moreNewsButton}
            className={styles.moreNewsButton}
            data={`${DATA_PHILTER}-more-news`}
            fullWidth
            inverted
            url="/more_news"
          />
        )}
      </div>
    );
  };

  return (
    <>
      {/* On Desktop view, the maximum number of content cards to show is defined in MAX_ITEMS_TO_SHOW_ON_DESKTOP */}
      <MediaWrapper greaterThanOrEqual={MEDIA_BREAKPOINTS.LG}>
        {newsWidget(MAX_ITEMS_TO_SHOW_ON_DESKTOP)}
      </MediaWrapper>

      {/* On Touch views, the maximum number of content cards to show is defined in MAX_ITEMS_TO_SHOW_ON_MOBILE */}
      <MediaWrapper
        className={styles.mobileTablet}
        lessThan={MEDIA_BREAKPOINTS.LG}
      >
        {newsWidget(MAX_ITEMS_TO_SHOW_ON_MOBILE)}
      </MediaWrapper>
    </>
  );
};

export default NewsWidget;
