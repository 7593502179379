export default function Message(): JSX.Element {
  return (
    <svg
      fill="none"
      height="13"
      viewBox="0 0 13 13"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ri:chat-4-fill">
        <path
          d="M3.33468 10.2917L1.03125 12.1875V2.16667C1.03125 2.02301 1.08572 1.88523 1.18269 1.78365C1.27965 1.68207 1.41117 1.625 1.54829 1.625H10.8551C10.9922 1.625 11.1237 1.68207 11.2207 1.78365C11.3176 1.88523 11.3721 2.02301 11.3721 2.16667V9.75C11.3721 9.89366 11.3176 10.0314 11.2207 10.133C11.1237 10.2346 10.9922 10.2917 10.8551 10.2917H3.33468Z"
          fill="#C9C9C9"
          id="Vector"
        />
      </g>
    </svg>
  );
}
