"use client";

import classNames from "classnames";
import { JSX, useMemo } from "react";
import { isMobileOnly } from "react-device-detect";
import styles from "rivals/components/shared/Ads/Ads.module.scss";
import useBenjiRender from "rivals/components/shared/Ads/Benji/BenjiRefresh/useBenjiRender";
import {
  DESKTOP_TOP_CENTER_PATH,
  E2E_AD_DIMS,
  E2E_DIM_0,
  E2E_DIM_1331,
  E2E_DIM_481,
  E2E_MOBILE_AD_DIMS,
  E2E_SIZE,
  LDRB_E2E_BP,
  MOBILE_TOP_CENTER_PATH,
  REGION,
  TOP_CENTER
} from "rivals/components/shared/Ads/Benji/constants";
import type { Props } from "rivals/components/shared/Ads/types";
import commonStrings from "rivals/shared/strings/common";
import { setData } from "rivals/shared/utils/qa";

const E2E_ID = "boxE2E";
const E2E_SLOT_NAME = "e2e";

/**
 * E2E (Edge to Edge) ad slot
 *
 * Renders the largest ad slot sold of E2E > Mast > LDRB
 *
 * Desktop - Supports E2E, MAST, LDRB
 *
 * Mobile - Supports E2E, WFPAD
 */
export default function E2E({
  adSettings,
  adStyles,
  forceAdToFront = false,
  isBenjiReady = false,
  slotNumber,
  userHasAdLite
}: Props): JSX.Element | null {
  const id = `${E2E_ID}${slotNumber && slotNumber > 1 ? slotNumber : ""}`;
  const slotName = `${E2E_SLOT_NAME}${
    slotNumber && slotNumber > 1 ? slotNumber : ""
  }`;

  const adSetting = "displayLdrbTop";

  // Define ad position configuration
  const positionConfig = useMemo(
    () => ({
      [id]: {
        customSizeConfig: {
          Horizon: {
            breakpoints: {
              [LDRB_E2E_BP.BP_0]: E2E_DIM_0,
              [LDRB_E2E_BP.BP_1331]: E2E_DIM_1331,
              [LDRB_E2E_BP.BP_481]: E2E_DIM_481
            },
            size: E2E_SIZE
          }
        },
        id,
        kvs: { loc: TOP_CENTER },
        path: isMobileOnly ? MOBILE_TOP_CENTER_PATH : DESKTOP_TOP_CENTER_PATH,
        region: REGION,
        size: isMobileOnly ? E2E_MOBILE_AD_DIMS : E2E_AD_DIMS
      }
    }),
    [id]
  );

  useBenjiRender({ isBenjiReady, positionConfig });

  // Respect ad settings for the slot or the user's ad lite subscription
  if ((adSettings && adSetting && !adSettings[adSetting]) || userHasAdLite) {
    return null;
  }

  return (
    <div className={styles.horizontalContainer} style={adStyles}>
      <div
        className={classNames(styles.adContainer, {
          [styles.forceAdToFront]: forceAdToFront
        })}
        id={id}
        {...setData(`banner-${slotName}-ad`)}
      >
        <span
          {...setData(slotName, "placeholder")}
          className={styles.adPlaceholder}
        >
          {commonStrings.advertisement}
        </span>
      </div>
    </div>
  );
}
